import UserAccount from "../../../Components/UserAccount";
import {Table} from "../../../Components/Table";
import React, {useContext, useEffect, useState} from "react";
import {Link, useLoaderData, useNavigate} from "react-router-dom";
import moment from "moment";
import {useTranslation} from "react-i18next";
import "../../../Assets/Css/Pages/User/Order/order.css"
import {UserIsNotLogged} from "../../../Components/UserUIMessages";
import {UserContext} from "../../../Contexts/UserContext";
import {getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";
import {FormProvider, useForm} from "react-hook-form";
import {Filters, Search} from "./Filters";
import {POST} from "../../../Hooks/RequestV2";
import {ListOptions} from "./ListOptions";
import {SavedCartModal} from "../SavedCarts/TransferSavedCartToCart";
import {Throbber} from "../../../Components/Throbber";
import {priceBuilder} from "../../../Hooks/Price";

function Orders(){
    const [orders, setOrders] = useState([]);
    const {t}                       = useTranslation();
    const {user} = useContext(UserContext);
    const filterForm              = useForm();
    const searchForm              = useForm();
    const navigate = useNavigate();
    const [throbber, setThrobber]       = useState(false);

    useEffect(() => {
        filterRequest();
    }, []);

    function filterRequest(){
        POST('/user/orders/filters', filterForm.watch())
            .then(response => response.json())
            .then(json => setOrders(json));
    }

    function searchRequest(){
        POST('/user/orders/search', searchForm.watch())
            .then(response => {
                if (response.status === 200){
                    return response.json();
                }
            })
            .then(json => setOrders(json));
    }

    function transformToCart(id){
        POST('/user/order/transform-to-cart', {order_id: id})
            .then(response => {
                if (response.status === 204){
                    navigate('/cart');
                }
            });
    }

    const columns = [
        {
            header: t('user-page.order.order-date'),
            accessorKey: 'createdAt',
            cell: ({getValue}) => {
                const dateTime = moment(getValue());

                return(
                    <>
                        {dateTime.format('YYYY-MM-DD')}
                    </>
                );
            }
        },
        {
            header: t('user-page.order.external-number'),
            accessorKey: 'externalNumberOrder1',
            cell: ({row, getValue}) => {

                if (getValue() !== '' && getValue() !== ' ' && getValue() !== null){
                    return(
                        <>
                            <Link to={'/user/order/app/'+encodeURIComponent((getValue()).replace('/', '-'))} >
                                <span className="order-href" >{getValue()}</span>
                            </Link>
                        </>
                    );
                }

                return (
                    <></>
                );
            }
        },
        {
            header: t('user-page.order.hurt-number'),
            accessorKey: 'hurtIdCanonical',
            cell: ({row, getValue}) => {
                if (getValue() !== null && getValue().indexOf('ETC') === -1){
                    return(
                        <>
                            <Link to={'/user/order/hurt/'+encodeURIComponent((getValue()).replace('/', '-'))} >
                                <span className="order-href" >{getValue()}</span>
                            </Link>
                        </>
                    );
                }

                return (
                    <></>
                );
            }
        },
        {
            header: t('user-page.order.total-order'),
            accessorKey: 'total',
            cell: ({row, getValue}) => {
                // let sum = 0;
                //
                // row.original.orderItem.map((item) => {
                //     sum += item.unitPrice * item.quantityInPackage;
                // });

                return(
                    <>
                        {priceBuilder(getValue(), row.original.currencyIso)}
                    </>
                );
            }
        },
        {
            header: t('user-page.order.order-state'),
            accessorKey: 'status',
            cell: ({row, getValue}) => {
                return(
                    <>
                        {t('user-page.order.status.'+getValue())}
                    </>
                );
            }
        },
        {
            header: t('user-page.order.option'),
            accessorKey: 'hurtIdCanonical',
            cell: ({row, getValue}) => {
                let type = 'hurt';

                if (getValue().includes('ETC')){
                    type = 'app';
                }

                return(
                    <>
                        <Link to={'/user/order/'+type+'/'+encodeURIComponent((getValue()).replace('/', '-'))} >
                            <button className="btn teal" >
                                <i className="fas fa-file-invoice" ></i> {t('user-page.invoices.details')}
                            </button>
                        </Link>

                        <SavedCartModal setThrobber={setThrobber} endpoint={'/user/order/transform-to-cart'} idItem={getValue()} />
                    </>
                );
            }
        },
    ];

    const table = useReactTable({
        data: orders,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    if (user === null){
        return (
            <UserIsNotLogged />
        );
    }

    if (orders === null){
        return (<></>);
    }

    const breadcrumb = [{name: t('user-page.order.title'), path: null}];

    return(
        <>
            <UserAccount title={t('user-page.order.title')} className="my-orders" breadcrumb={breadcrumb} >
                <div className={'filters'} >
                    <FormProvider {...searchForm} >
                        <Search onSubmit={searchRequest} />
                    </FormProvider>
                    <div className={'or'} >{t('user-page.invoices.or')}</div>
                    <FormProvider {...filterForm} >
                        <Filters onSubmit={filterRequest} />
                    </FormProvider>
                </div>

                <Throbber visible={throbber} />

                {
                    orders && orders.length > 0 ?
                        <>
                            <ListOptions
                                table={table}
                                rowQuantity={orders.length}
                                filterRequest={filterRequest}/>
                            <Table table={table} />
                        </>
                        :
                        <div className={'empty'} >{t('user-page.order.empty-list')}</div>
                }
            </UserAccount>
        </>
    );
}

export default Orders;
