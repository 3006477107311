import {Post} from "../Hooks/Request";

/**
 * User login form authorization
 */
function Auth(){
    const {response, setBody, httpStatusCode} = Post("/user/login_auth");

    return {response, setBody, httpStatusCode};
}

export default Auth;
