import React, {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {MenuFilters} from "../../Requests/Filters";
import {SubMenu, Menu, useMenu} from "../../Components/TreeScrollMenu"
import {useStates} from "../../Components/ScrollMenu/States";
import {Link} from "react-router-dom";
import {UserContext} from "../../Contexts/UserContext";
import {MergeAlphabetical} from "../../Components/ScrollMenu/MergeAlphabetical";
import {SearchInFilter} from "../../Components/ScrollMenu/SearchInFilter";
import {useForceReload} from "../../Hooks/ForceReload";
import {HeaderContext} from "../../Contexts/HeaderContext";

function HeaderMenu() {
    const {t} = useTranslation();
    const headerContext = useContext(HeaderContext);

    if (headerContext.filtersHttpStatusCode >= 400){
        throw new Error('Filters not found. Check request exception.');
    }

    if (headerContext.filters === null){
        return(
            <menu>
                <div className={'container'} >
                    <div className="menuitem"  >
                        <div className={'label'} >
                            <span>{t('layout.menu.categories')}</span>
                        </div>
                    </div>
                    <div className="menuitem" >
                        <div className={'label'} >
                            <span>{t('layout.menu.producers')}</span>
                        </div>
                    </div>
                    <div className="menuitem" >
                        <div className={'label'} >
                            <span>{t('layout.menu.brands')}</span>
                        </div>
                    </div>
                    <div className="menuitem" >
                        <div className={'label'} >
                            <span>{t('layout.menu.licenses')}</span>
                        </div>
                    </div>

                    <div className="menuitem"  >
                        <Link to="/products-list/news" >
                            <div className={'label'} >
                                <span>{t('layout.menu.news')}</span>
                            </div>
                        </Link>
                    </div>
                    <div className="menuitem" >
                        <Link to="/products-list/promotions" >
                            <div className={'label'} >
                                <span>{t('layout.menu.promotions')}</span>
                            </div>
                        </Link>
                    </div>
                    <div className="menuitem" >
                        <Link to="/products-list/sales" >
                            <div className={'label'} >
                                <span>{t('layout.menu.sales')}</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </menu>
        );
    }

    return(
        <menu>
            <div className="container" >
                <Categories data={headerContext.filters.filters.categories} />
                <Producers data={headerContext.filters.filters.producers} />
                <Brands data={headerContext.filters.filters.brands} />
                {headerContext.filters.filters.licenses.length > 0 ? <Licenses data={headerContext.filters.filters.licenses} /> : null}
                <div className="menuitem"  >
                    <Link to="/products-list/news" >
                        <div className={'label'} >
                            <span>{t('layout.menu.news')}</span>
                        </div>
                    </Link>
                </div>
                <div className="menuitem" >
                    <Link to="/products-list/promotions" >
                        <div className={'label'} >
                            <span>{t('layout.menu.promotions')}</span>
                        </div>
                    </Link>
                </div>
                <div className="menuitem" >
                    <Link to="/products-list/sales" >
                        <div className={'label'} >
                            <span>{t('layout.menu.sales')}</span>
                        </div>
                    </Link>
                </div>
            </div>
        </menu>
    );
}

function useSearch(data, propertyAlphabeticalName = 'name'){
    const alphabetData              = new MergeAlphabetical(data, propertyAlphabeticalName).getMergedData();
    const {states}                  = useStates();
    const [dataList, setDataList]   = useState(alphabetData);

    useEffect(()=>{
        setDataList(alphabetData);
    }, [data]);

    useEffect(()=>{
        const searchResult = new SearchInFilter(states.search, alphabetData).getResults();

        if (searchResult !== null && searchResult.length > 0){
            setDataList(searchResult);
            states.setClickableFirstMenu(true);
            states.setEmptyResultsSearching(false);
        } else {
            setDataList(alphabetData);
            states.setClickableFirstMenu(false);
            states.setEmptyResultsSearching(true);
        }
    }, [states.search]);

    useEffect(()=>{
        states.setClickableFirstMenu(false);
        states.setEmptyResultsSearching(false);
    }, []);

    return {states, dataList, alphabetData}
}

function useSearchCategory(data){
    // const alphabetData              = new MergeAlphabetical(data, propertyAlphabeticalName).getMergedData();
    const {states}                  = useStates();
    const [dataList, setDataList]   = useState(data);

    function searchInArray(){
        let rawData = [];
        let filteredData = [];

        data.map((cat1) => {
            // rawData = [...rawData, cat1];
            cat1.children.map((cat2) => {

                if (!rawData.find(item => item.id === cat2.id)){
                     const newName = cat2.hurt_name+' ('+cat1.hurt_name+')';

                    rawData = [...rawData, {...cat2, hurt_name: newName}];
                }
                // cat2.children.map((cat3) => {
                //     // rawData = [...rawData, cat3];
                // });
            });
        });

        rawData.map((categoryName) => {
            if (states.search !== null && categoryName.hurt_name.toLowerCase().includes(states.search.toLowerCase())){
                filteredData = [...filteredData, {hurt_name: categoryName.hurt_name, id: categoryName.id}];
            }
        });

        if (states.search && filteredData.length > 0){
            return filteredData;
        }

        return null;
    }

    useEffect(()=> {
        const searchResult = searchInArray();
        if (searchResult !== null){
            setDataList(searchResult);
            states.setClickableFirstMenu(true);
            states.setEmptyResultsSearching(false);
        } else {
            states.setEmptyResultsSearching(true);
            setDataList(data);
            states.setClickableFirstMenu(true);
        }
    }, [states.search]);

    useEffect(()=> {
        states.setClickableFirstMenu(true);
        states.setEmptyResultsSearching(false);
    }, []);

    return {states, dataList, alphabetData: data}
}

/**
 * Categories menu
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
function Categories({data}){
    const {t}               = useTranslation();
    const ref               = useRef(null);
    const {dataList, alphabetData, states}  = useSearchCategory(data);
    const {hoverSubMenu2}   = useMenu(ref, states);

    return(
        <Menu promoted={data}
              data={dataList}
              name={t('layout.menu.categories')}
              ref={ref}
              states={states}
              itemText="hurt_name"
              search={"true"}
              path="categories"
              isCategory={true}>

            <SubMenu data={dataList}
                     itemsName="children"
                     itemsText="hurt_name"
                     className="submenu-list1"
                     hover={hoverSubMenu2}
                     path="categories" />

            {!states.search && Object.values(data).map((category, key)=>{
                return <SubMenu key={key}
                                data={category.children}
                                itemsName="children"
                                itemsText="hurt_name"
                                className="submenu-list2"
                                hover={null}
                                path="categories" />
            })}
        </Menu>
    );
}

/**
 * Producers menu
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
function Producers({data}){
    const {t}                               = useTranslation();
    const ref                               = useRef(null);
    const {dataList, alphabetData, states}  = useSearch(data);

    return(
        <Menu data={dataList}
              promoted={data}
              name={t('layout.menu.producers')}
              ref={ref}
              states={states}
              itemText="name"
              search="true" path="producers" >

            <SubMenu data={alphabetData}
                     itemsName="children"
                     itemsText="name"
                     className="submenu-list1 end"
                     hover={null} path="producers" />
        </Menu>
    );
}

/**
 * Brands menu
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
function Brands({data}){
    const {t}                               = useTranslation();
    const ref                               = useRef(null);
    const {dataList, alphabetData, states}  = useSearch(data);

    return(
        <Menu data={dataList}
              promoted={data}
              name={t('layout.menu.brands')}
              ref={ref}
              states={states}
              itemText="name"
              search="true" path="brands" >

            <SubMenu data={alphabetData}
                     itemsName="children"
                     itemsText="name"
                     className="submenu-list1 end"
                     hover={null} path="brands" />
        </Menu>
    );
}


/**
 * Brands menu
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
function Licenses({data}){
    const {t}                               = useTranslation();
    const ref                               = useRef(null);
    const {dataList, alphabetData, states}  = useSearch(data);

    return(
        <Menu data={dataList}
              promoted={data}
              name={t('layout.menu.licenses')}
              ref={ref}
              states={states}
              itemText="name"
              search="true" path="licenses" >

            <SubMenu data={alphabetData}
                     itemsName="children"
                     itemsText="name"
                     className="submenu-list1 end"
                     hover={null} path="licenses" />
        </Menu>
    );
}



export default HeaderMenu;
