import React from "react";
import {useFormContext} from "react-hook-form";
import {FilterComponent} from "./FilterComponent";
import {useTranslation} from "react-i18next";

function ProductEAN(): React.ReactElement {
    const form = useFormContext();
    const {t} = useTranslation();

    return (
        <FilterComponent title={t('user-page.invoice.filters.product-ean')} >
            <input type={'text'} {...form.register('product-ean')} />
        </FilterComponent>
    );
}

export {ProductEAN};