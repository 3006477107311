import React from "react";
import {CartType} from "../Types/CartType";

interface CartContextInterface{
    cart: CartType|null,
    setCart: React.Dispatch<CartType|null>
}

const CartContext = React.createContext<CartContextInterface|null>(null);

export {CartContext, type CartContextInterface};
