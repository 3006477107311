import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/Css/Pages/Home/banners.css";
import Slider from "react-slick";
import {useEffect, useState} from "react";
import {GET} from "../../Hooks/RequestV2";
import RequestConfig from "../../Requests/RequestConfig.json";

/**
 * CSS Grid home page banners
 */
function Banners(){
    const [banners, setBanners] = useState();

    useEffect(() => {
        GET('/banners/home')
            .then((results) => {
                if (results.ok){
                    return results.json().then((json) => setBanners(json));
                }
            });
    }, []);


    if (!banners || banners.length === 0){
        return <></>;
    }

    if (banners.length === 1){
        return (
            <div className={'slick-slider'} >
                <BannerItem banner={banners[0]} />
            </div>
        );
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return(
        <>
            <Slider {...settings} >
                {banners.map((banner, key) => <BannerItem key={key} banner={banner} />)}
            </Slider>
        </>
    );
}

/**
 * Banners item
 */
function BannerItem({banner}){
    const url = banner.url.indexOf('http') !== -1 ? banner.url : window.location.protocol+'//'+window.location.host+banner.url;

    return(
        <a href={url} >
            <div className={'banner'} style={{'backgroundImage': 'url('+RequestConfig.domain+'/assets/banner/'+banner.image+')'}} ></div>
        </a>

    );
}

export {Banners};
