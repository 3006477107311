import React from "react";
import {ProductsListType} from "../Types/ProductsListType";

interface HeaderContextInterface{
    filters: object[],
    getFilters: React.Dispatch<any>,
    filtersHttpStatusCode: number
}

const HeaderContext = React.createContext<HeaderContextInterface|null>(null);

export {HeaderContext};