/**
 * Mapping HOOK
 *
 * @returns {{getCookie: ((function(*): (string|undefined))|*)}}
 */
function useCookie(){
    return {getCookie, setCookie};
}

/**
 * Get Cookie
 *
 * @param cookieName
 * @returns {string}
 */
function getCookie(cookieName){
    const cookies = decodeURIComponent(document.cookie).split(';');
    let key, data;

    for (key in cookies){
        data = cookies[key].split('=');

        if (cookieName === data[0].replace(/ /g,'')){
            return data[1];
        }
    }
}

/**
 * Set cookie
 *
 * @param param
 * @param value
 * @param days
 */
function setCookie(param, value, days = 365){
    const date  = new Date();

    date.setTime(date.getTime() + (1000 * 60 * 60 * 24 * days));

    document.cookie = param+'='+value+'; expires='+date.toUTCString()+'; path=/';
}

export {useCookie, getCookie, setCookie};
