import {FieldValues} from "react-hook-form";

function getRawData(rawData: FieldValues, removeKey: string|null = null){
    for (let v in rawData){
        if (
            rawData[v] === false ||
            rawData[v] === null ||
            rawData[v] === '' ||
            rawData[v] === undefined ||
            rawData[v].length === 0){
            delete rawData[v];
        }
    }

    if (removeKey !== null) {
        delete rawData[removeKey];
    }

    return rawData;
}

export {getRawData}
