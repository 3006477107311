import React, {useEffect, useState} from "react";

/**
 * Simple modal component
 */
function SimpleModal({visibility, wait, className, children, hidden}) {

    const [visible, setVisible] = useState('');
    const [propsVisible, setPropsVisible] = useState(wait === false);

    useEffect(() => {
        if (visibility === false && visibility !== null)  {
            setVisible('hidden');
        } else if (visibility === true) {
            setVisible('visible');
        } else if (visibility === null) {
            setVisible('');
        }
    }, [visibility]);

    useEffect(() => {
        if (visible === 'hidden'){
            setTimeout(() => {
                setPropsVisible(false);
            }, 500);
        } else if (visible === 'visible') {
            setPropsVisible(true);
        }
    }, [visible])

    return(
        <>
            <div className={'simple-modal '+visible+(className ? ' '+className : '')} >
                <div className="wrapper" >
                    <div className="simple-modal-box" >
                        <div className="wrapper" >
                            <button type={'button'} className="fas fa-times" onClick={hidden} ></button>
                            {propsVisible === true ? children : ''}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

/**
 * Simple modal HOOK for visibility state and toggle it
 */
function useSimpleModalState() {
    const [visibility, setVisibility] = useState(null);

    function toggleVisibleModal(){
        if (visibility === false) {
            setVisibility(true)
        } else {
            setVisibility(!visibility);
        }
    }

    return {visibility, setVisibility, toggleVisibleModal};
}

export {SimpleModal, useSimpleModalState};
