import {Children, useState} from "react";
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";
import FilterDefaultData from "../Pages/ProductsList/Tools/FiltersDefaultData.json";
import {collectDataForm} from "../Pages/ProductsList/FiltersDataHelper";
import {useParams, useSearchParams} from "react-router-dom";

function Filter({name, children, resetVisible, filterInputName, send}){
    const form                      = useFormContext();
    const {t}                       = useTranslation();
    const {filter, id}              = useParams();
    const [searchParams, setSearchParams]   = useSearchParams();
    const numberOfItemToHidden      = 10;
    const arrayChildren             = Children.toArray(children);
    const [visible, setVisible]     = useState(false);
    const more                      = t('layout.filters.more');
    const less                      = t('layout.filters.less');

    function toggleVisible(){
        setVisible(!visible);
    }

    function resetFilter(e){
        filterInputName.map((value, key) => {
            form.resetField(value, {defaultValue: FilterDefaultData[value].emptyValue});
        });

        collectDataForm(form, {name: filter, id}, setSearchParams);
    }

    return(
        <>
            <div className="filter" >
                <div className="header" >
                    <h3>{name}</h3>
                    {resetVisible === true ?
                        <button onClick={(e) => resetFilter(e)} >
                            <i className="fas fa-times" ></i> {t('layout.filters.reset-filter')}
                        </button>
                      : ''
                    }
                </div>
                <div className="options" >
                    {arrayChildren.map((value, key) => {
                        if (key <= numberOfItemToHidden){
                            return value;
                        }
                    })}
                </div>
                <div className={'hidden'+(visible === true ? ' open' : '')} >
                    {arrayChildren.map((value, key) => {
                        if (key > numberOfItemToHidden){
                            return value;
                        }
                    })}
                </div>
                {(children.length > 7 ? <button className="more" type="button" onClick={toggleVisible} >{(visible === true ? less : more)}</button> : null)}
            </div>
        </>
    );
}

export default Filter;
