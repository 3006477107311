import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {ChannelContext} from "../../Contexts/ChannelContext";

/**
 * Footer component
 */
function Footer(){
    const {t} = useTranslation();
    const channelConext = useContext(ChannelContext);

    const footer = [
        {
            title: t('layout.footer.customer-service'),
            urls: [
                {url: '#1', text: t('layout.footer.customer-service-contact')},
                {url: '#2', text: t('layout.footer.complaint-form')},
                {url: '#3', text: t('layout.footer.faq')},
                {url: '#4', text: t('layout.footer.my-account')},
                {url: '#5', text: t('layout.footer.return-form')},
            ]
        },
        {
            title: t('layout.footer.useful-links'),
            urls: [
                {url: '#1', text: t('layout.footer.customer-service-contact')},
                {url: '#2', text: t('layout.footer.complaint-form')},
                {url: '#3', text: t('layout.footer.faq')},
                {url: '#4', text: t('layout.footer.my-account')},
                {url: '#5', text: t('layout.footer.return-form')},
            ]
        }
    ];

    return(
        <footer>
            <div className="title-container" >
                <div className="wrapper" >
                    {/*{footer.map((title, key)=> <Title key={key} data={title} />)}*/}
                    <div className="title contact" >{t('layout.footer.contact')}</div>
                </div>

            </div>
            <div className="urls-container" >
                <div className="wrapper" >
                    {/*{footer.map((title, key)=> <UrlsContainer key={key} data={title} />)}*/}
                    <div className="urls contact" >
                        {channelConext.isExportDepartment ?
                            <>
                                +48 12 61 44 264<br/>
                                export@euro-trade.pl<br/>
                            </>
                            :
                            <>
                                +48 12 61 44 100<br/>
                                biuro@euro-trade.pl<br/>
                            </>
                        }
                    </div>
                </div>
            </div>
        </footer>
    );
}

/**
 * Footer title
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
function Title({data}){
    return(
        <>
            <div className="title" >{data.title}</div>
        </>
    );
}

/**
 * URL's container
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
function UrlsContainer({data}){
    return(
        <div className="urls" >
            {data.urls.map((url, key)=> <Url key={key} k={key+1} data={url} />)}
        </div>
    );
}

/**
 * URL item
 *
 * @param key
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
function Url({data, k}){
    return(
        <>
            <a href={'#'+data.text+'-'+k} ><i className="fas fa-angle-right" ></i> {data.text}</a>
        </>
    );
}

export default Footer;
