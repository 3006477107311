import {useLoaderData, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import Breadcrumb from "../../Components/Breadcrumb";
import "../../Assets/Css/Pages/Product/product.css"

import {CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {useProductData} from "../ProductsList/ProductData";
import {useTranslation} from "react-i18next";
import {useAdd2Cart} from "../../Requests/Cart";
import {CartContext} from "../../Contexts/CartContext";
import {UserContext} from "../../Contexts/UserContext";
import {QuantityDisposition} from "../ProductsList/Components/QuantityDisposition";
import {ChannelContext} from "../../Contexts/ChannelContext";
import RequestConfig from "../../Requests/RequestConfig.json";
import {GET} from "../../Hooks/RequestV2";
import {getCookie} from "../../Hooks/Cookies";

function Product(){
    const loader = useLoaderData();
    const {slug}                = useParams();

    const [response, setResponse] = useState(loader);
    const product               = useProductData(response);
    const {t}                   = useTranslation();

    if (response === null){
        return <></>;
    }

    const breadcrumb = [{name: t('products-list.products-list'), path: null}, {name: product.name, path: null}];

    return(
        <>
            <Breadcrumb breadcrumb={breadcrumb} />
            <div className="product" >
                <div className="product-gallery" >
                    {product.isSales && <span className={'sales'} >{t('layout.filters.sale')}</span>}
                    <Gallery images={product.images} isGallery={product.isGallery} />
                </div>
                <div className="product-desc" >
                    <ProductData product={product} />
                </div>
                <div className="description" >
                    <div className="title" >{t('product-page.description')}</div>
                    <div dangerouslySetInnerHTML={{__html: product.description}} />
                </div>
            </div>
        </>
    );
}

function Gallery({images, isGallery}){

    function getButtons(){
            return(
                <>
                    <ButtonBack>
                        {isGallery ? <i className="fas fa-angle-left" /> : ''}
                    </ButtonBack>
                    <ButtonNext>
                        {isGallery ? <i className="fas fa-angle-right" /> : ''}
                    </ButtonNext>
                </>
            );

        return '';
    }

    function getThumbnails(){
        if (isGallery === true){
            return(
                <>
                    {images.map((image, index) => {
                        return (
                            <Dot slide={index} key={index} >
                                <img src={image} className="big-img" />
                            </Dot>
                        );
                    })}
                </>
            );
        }

        return '';
    }

    return(
        <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={100}
            totalSlides={images.length}
        >
            {getButtons()}
            <Slider>
                {images.map((image, index) => {
                    return (
                        <Slide index={index} key={index} >
                            <img src={image} className="big-img" />
                        </Slide>
                    );
                })}
            </Slider>
            <div className="thumbs" >
                {getThumbnails()}
            </div>
        </CarouselProvider>
    );
}

/**
 *
 * @param product
 * @returns {JSX.Element}
 * @constructor
 */
function ProductData({product}){
    const {setUser, user}       = useContext(UserContext);
    const {setCart}             = useContext(CartContext);
    const channelContext        = useContext(ChannelContext);
    const {t, i18n}                   = useTranslation();

    const {response, setBody}   = useAdd2Cart();

    function downloadImages(){
        GET('/user/product/image/'+product.id)
        .then((response) => {
            if (response.status === 200){
                return response.blob();
            }
        }).then((blob) => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');

            let name = 'Zdjęcia do produktu nr ' + product.indexHurt;

            a.href = url;
            a.download = name + '.zip';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    }

    function calculatedPackage(e, product){
        const value = e.currentTarget.value;

        add2Cart(product, value);

        product.ref.current.value = (value * product.quantityPackage)+' '+ product.unit;
    }

    function add2Cart(product, quantity){
        setBody({product_id: product.id, quantity: quantity});
    }

    // @TODO Gówno do kwadratu - ale nie było wyjścia innego żeby to szybko zrobić ...
    function userMustLogged(){
        document.querySelector('#login-button').click();
        document.querySelector('.login-form .form-notification').innerHTML = '<div class="form-error" >\n' +
            '                <i class="fas fa-times-circle"></i>\n' +
            '                <span>'+t('layout.login-form.buy-not-logged')+'</span>\n' +
            '            </div>';
    }

    function displayPrice() {
        if (!user && channelContext?.displayPriceNotLogged === true){
            return <div className={'prices'} ><span>{t('products-list.disable-price-not-logged')}</span></div>
        }

        if (user && channelContext?.displayPriceLogged === true){
            return <div className={'prices'}><span>{t('products-list.disable-price-logged')}</span></div>
        }

        return (
            <div className="prices" >
                <div className="price-title" >{t('products-list.price')}</div>
                <div className="price-wrapper" >
                    {product.priceNetto > 0 ?
                        <>
                            <div className="old-price" >{product.priceBefore} {t('products-list.before-tax')}</div>
                            <div className="netto" >{product.priceNetto} {t('products-list.before-tax')}</div>
                            <div className="brutto" >{product.priceBrutto} {t('products-list.after-tax')}</div>
                        </>
                        :
                        <>
                            <div className="netto" >{product.priceBefore} {t('products-list.before-tax')}</div>
                            <div className="brutto" >{product.priceBeforeBrutto} {t('products-list.after-tax')}</div>
                            {product.isNoDiscount ? <div className={'final-price'} >{t('products-list.final-price')}</div> : ''}
                        </>
                    }


                </div>
            </div>
        );
    }

    function displayQuantity() {
        if (!user && channelContext?.displayQuantityNotLogged === true){
            return <div className="quantity" ><span>{t('products-list.disable-quantity-not-logged')}</span></div>
        }

        if (user && channelContext?.displayQuantityLogged === true){
            return <div className="quantity" ><span>{t('products-list.disable-quantity-logged')}</span></div>
        }

        return (
            <div className="quantity" >
                <span>{t('products-list.avail')}</span>
                <span><QuantityDisposition quantity={product.quantity} unit={product.unit} /></span>
            </div>
        );
    }

    return(
        <>
            <h2>{product.name}</h2>
            {displayPrice()}
            <div className="attributes" >
                <div className="index" >
                    <span>{t('products-list.index-et')}</span>
                    <span>{product.indexHurt}</span>
                </div>
                <div className="ean" >
                    <span>{t('products-list.ean')}</span>
                    <span>{product.ean !== null ? product.ean : '-'}</span>
                </div>
                <div className="ean" >
                    <span>{t('product-page.alternative-eans')}</span>
                    <span>{product.eans !== null ? product.eans : '-'}</span>
                </div>

                {product.show_supplier_code ?

                    <div className={'producer-index'}>
                        <span>{t('product-page.producer-index')}</span>
                        <span>{product.producerIndex}</span>
                    </div>

                    : null}


                <div className="producer" >
                    <span>{t('products-list.producer')}</span>
                    <span>{product.producerName}</span>
                </div>
                <div className="brand" >
                    <span>{t('products-list.brand')}</span>
                    <span>{product.brandName}</span>
                </div>
                <div className="license" >
                    <span>{t('products-list.license')}</span>
                    <span>{product.licenseName}</span>
                </div>
                <div className="license" >
                    <span>{t('products-list.unit')}</span>
                    <span>{product.unit}</span>
                </div>
                <div className="package" >
                    {(!user && channelContext?.displayQuantityNotLogged === true) || (user && channelContext?.displayQuantityLogged === true) ? null :
                        <>
                            <span>{t('products-list.in-package')}</span>
                            <span>{product.quantityPackage}</span>
                        </>
                    }

                </div>
                {displayQuantity()}

            </div>

            <div className={'bottom-desc'} >
                {user ?

                    <div className={'download-images'} >
                        <button className={'btn'} onClick={() => downloadImages()} >{t('product-page.download-images')}</button>
                    </div>

                    : null}


                {user && channelContext?.displayQuantityLogged === true ? null :
                    <div className="add-to-cart" >
                        <div className="field" >
                            <input
                                type="text"
                                name="quantity-package"
                                disabled="disabled"
                                defaultValue={(product.cartItemQuantity !== 0 ? (product.cartItemQuantity * product.quantityPackage)+' '+ product.unit : undefined)}
                                ref={product.ref} />
                        </div>
                        <div className="field" >
                            {t('products-list.quantity')} <input
                            type="number"
                            name="quantity"
                            step="1"
                            min="0"
                            max={product.quantity}
                            defaultValue={(product.cartItemQuantity !== 0 ? product.cartItemQuantity : undefined)}
                            onChange={(e) => {
                                if (user !== false) {
                                    calculatedPackage(e, product);
                                } else {
                                    userMustLogged();
                                }
                            }} />
                        </div>
                    </div>
                }
            </div>

        </>
    );
}

export default Product;
