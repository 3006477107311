function SetContractorFromAdmin(): void {
    const params = new URLSearchParams(window.location.search);

    params.forEach((value, key) => {
        if (key === 'contractor'){
            localStorage.setItem(key, value);
        }
    });
}

export {SetContractorFromAdmin};