import React, {useContext, useEffect, useState} from "react";
import {TransferSavedCartToCurrent} from "../../../Requests/User";
import {useNavigate} from "react-router-dom";
import {CartContext} from "../../../Contexts/CartContext";
import {GET, POST} from "../../../Hooks/RequestV2";
import {SimpleModal, useSimpleModalState} from "../../../Components/SimpleModal";
import {useTranslation} from "react-i18next";

function useTransferSavedCartToCart(setThrobber: React.Dispatch<any>, idItem: number, endpoint: string){
    const cartContext = useContext(CartContext);
    const navigate    = useNavigate();

    function saveCartInCurrentCart(path: string){
        setThrobber(true);
        POST(endpoint, {id: idItem})
            .then((response) => {
                if (response.ok){
                    GET('/user/cart')
                        .then((response) => response.json())
                        .then((json) => {
                            setThrobber(false);
                            cartContext!.setCart(json);
                            navigate(path);
                        });
                }
            });
    }

    return saveCartInCurrentCart;
}

function SavedCartModal({setThrobber, idItem, endpoint}: {setThrobber:React.Dispatch<any>, idItem: number, endpoint: string}): React.ReactElement {
    const {visibility, setVisibility, toggleVisibleModal} = useSimpleModalState();
    const sendItems = useTransferSavedCartToCart(setThrobber, idItem, endpoint);
    const cartContext = useContext(CartContext);
    const {t} = useTranslation();
    const splitURL = endpoint.split('/');
    const navigate = useNavigate();
    const [path, setPath] = useState<string>('/cart?saved-cart-transfer=true');

    function enabledModal(): void {
        if (cartContext?.cart?.products.length !== 0){
            setVisibility(true as any);
        } else {
            sendItems(path);
        }
    }

    function isActiveOrServiceItemsValidator(): void {
        POST('/user/validate/document', {id: idItem, type: splitURL[2]})
            .then((response) => {
                if (response.ok){
                    return response.json();
                }
            })
            .then((json) => {
                if (json === 'only_service' || json === 'not_active'){
                    navigate('/cart?failed-cart-transfer=true')
                } else if (json === 'all_active') {
                    enabledModal();
                    setPath('/cart?saved-cart-transfer=true');
                } else {
                    enabledModal();
                    setPath('/cart?mixed-cart-transfer=true');
                }
            });

    }

    return (
        <>
            <button type={'button'} className="btn green" onClick={() => isActiveOrServiceItemsValidator()} >
                <i className="fas fa-cart-arrow-down" ></i> {t('user-page.saved-carts.add-to-cart')}
            </button>
            <SimpleModal className={'add-to-cart-modal'} hidden={toggleVisibleModal} visibility={visibility} wait={true} >
                <p>{t('user-page.saved-carts.add-to-cart-modal-text')}</p>

                <div className={'buttons'} >
                    <button type={'button'} className={'btn red'} onClick={() => setVisibility(false as any)} >{t('user-page.saved-carts.add-to-cart-modal-no')}</button>
                    <button type={'button'} className={'btn green'} onClick={() => { setVisibility(false as any); sendItems(path); }} >{t('user-page.saved-carts.add-to-cart-modal-yes')}</button>
                </div>
            </SimpleModal>
        </>
    );
}

export {SavedCartModal}