import React from "react";
import {FilterComponent} from "./FilterComponent";
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";

function DateRange(): React.ReactElement {
    const form = useFormContext();
    const {t} = useTranslation();

    return (
        <FilterComponent title={t('user-page.invoice.filters.date-range')} className={'date-range'} >
            <input type={'date'} {...form.register('date-from')} />
            <input type={'date'} {...form.register('date-to')} />
        </FilterComponent>
    );
}

export {DateRange};