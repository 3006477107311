import defaultData from "./FiltersDefaultData.json";

class BuildResetFiltersObject{
    private _urlObject: object|null;

    constructor(urlObject: object|null = null) {
        this._urlObject = urlObject;
    }

    public set urlObject(urlObject: object){
        this._urlObject = urlObject;
    }

    private getfilterData(filter: string){
        if (this._urlObject === null){
            throw new Error('Property urlObject is null (object build from url using method like mappingURLtoForm needed).');
        }

        if (filter in this._urlObject){
            return  this._urlObject[filter as keyof object];
        }

        return defaultData[filter as keyof typeof defaultData].emptyValue;
    }

    public getRebuildObject(): object{
        const buildFinalData: any = {};

        Object.keys(defaultData).map((filter: string) => {
            buildFinalData[filter] = this.getfilterData(filter);
        });

        return buildFinalData;
    }
}

export {BuildResetFiltersObject};