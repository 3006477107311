function SetContractorFromUrl(): void {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const broadcastChannel = new BroadcastChannel('change-contractor-local-storage');

    if (params.has('contractor')){
        localStorage.setItem('contractor', params.get('contractor') as string);
        broadcastChannel.postMessage({contractorId: params.get('contractor') as string});

        url.searchParams.delete('contractor');

        window.history.replaceState({}, '', url);
    }
}

export {SetContractorFromUrl};