import {useFormContext} from "react-hook-form";
import {useParams, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useCookie} from "../../Hooks/Cookies";
import {PaginationHelper} from "./Components/Pagination";

/**
 * Quantity product of page
 */
function ProductsPerPage({send}){
    const perPage                   = [30, 60, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];
    const {setCookie, getCookie}    = useCookie();
    const {t}                       = useTranslation();
    const selectOption              = (getCookie('per-page') ? parseInt(getCookie('per-page')) : 30);

    function addPerPageToCookie(e){
        const value = e.target.value;

        setCookie('per-page', value);
        send();
    }

    return(
        <div className="per-page" >
            <span className="title" >{t('layout.products-per-page')}: </span>
            <select defaultValue={selectOption} onChange={addPerPageToCookie} >
                {(perPage.map((value, key)=>{
                    return <option value={value} key={key} >{value}</option>
                }))}
            </select>
        </div>
    );
}

/**
 * Sorting products
 */
function Sort({send}){
    const {setCookie, getCookie}    = useCookie();
    const {t}                       = useTranslation();
    const selectOption              = (getCookie('sort') ? parseInt(getCookie('sort')) : 0);
    const options                   = [
        {name: t('layout.sort.default'),     value: 0},
        {name: t('layout.sort.index-up'),    value: 1},
        {name: t('layout.sort.index-down'),  value: 2},
        {name: t('layout.sort.name-up'),     value: 3},
        {name: t('layout.sort.name-down'),   value: 4},
        {name: t('layout.sort.price-up'),    value: 5},
        {name: t('layout.sort.price-down'),  value: 6},
        {name: t('layout.sort.avail-up'),    value: 7},
        {name: t('layout.sort.avail-down'),  value: 8},
    ];

    function addSortToCookie(e){
        const value = e.target.value;

        setCookie('sort', value);
        send();
    }

    return(
        <div className="sort" >
            <span className="title" >{t('layout.sort.title')}: </span>
            <select defaultValue={selectOption} onChange={addSortToCookie} >
                {options.map((option, key)=>{
                    return <option value={option.value} key={key} >{option.name}</option>
                })}
            </select>
        </div>
    );
}

/**
 * Products list types
 */
function ListTypeNav({type, setType}){
    const {setCookie, getCookie}    = useCookie();
    const {t} = useTranslation();

    function addListTypeToCookie(value){
        setCookie('list-type', value);
        setType(value);
    }

    return(
        <div className="list-type" >
            <span>{t('products-list.choice-list-style')}:</span>
            <button
                className={(type === 'old-platform' ? 'active': '')}
                onClick={() => addListTypeToCookie('old-platform')} >
                <i className="fas fa-grip-horizontal"></i>
            </button>
            <button
                className={(type === 'matrix' ? 'active': '')}
                onClick={() => addListTypeToCookie('matrix')} >
                <i className="fas fa-th" ></i>
            </button>
            <button
                className={(type === 'list' ? 'active': '')}
                onClick={() => addListTypeToCookie('list')} >
                <i className="fas fa-th-list" ></i>
            </button>
            <button
                className={(type === 'tabular' ? 'active': '')}
                onClick={() => addListTypeToCookie('tabular')} >
                <i className="fas fa-list-ul" ></i>
            </button>
        </div>
    );
}

/**
 * Get amount of all search products
 */
function ProductsCount({count}){
    const {t} = useTranslation();

    return(
        <div className="quantity" >
            {t('layout.find')}: <span className="count" >{count}</span> {t('layout.products')}
        </div>
    );
}

/**
 * Pagination list
 */
function Pagination({count, send, activePage, setActivePage}){
    const form                              = useFormContext();
    const {getCookie}                       = useCookie();
    const perPage                           = (getCookie('per-page') ? parseInt(getCookie('per-page')) : 30);
    const pagesLimitInFrontAndBack          = 3;
    const helper                            = new PaginationHelper(count, perPage, activePage, pagesLimitInFrontAndBack, setActivePage, send);

    return(
        <div className="pagination">
            {helper.getBackButton()}
            {helper.getPages()}
            {helper.getForwardButton()}
        </div>
    );
}

export {ProductsPerPage, ProductsCount, Sort, Pagination, ListTypeNav}
