import {Delete, Get, Post} from "../Hooks/Request";
import {useEffect} from "react";

/**
 * Add product to cart
 *
 * @returns {{response, setBody}}
 * @constructor
 */
function useAdd2Cart(){
    const {response, setBody, httpStatusCode} = Post('/user/cart/add');

    useEffect(() => {
        if (httpStatusCode !== 0){
            const broadcastChannel = new BroadcastChannel('reload-cart');

            broadcastChannel.postMessage('reload');
        }

    }, [httpStatusCode]);

    return {response, setBody};
}

/**
 * Get number of items in cart, for header icon/button cart
 *
 *
 * @returns {{setSend, response}}
 */
function CartItemAmount(){
    const {response, setSend} = Get('/user/cart/item-amount');

    return {cartItemAmount: response, setSend};
}

/**
 * @returns {{setSend, cartItems}}
 */
function getCartItems(){
    const {response, setSend} = Get('/user/cart');

    return {products: response, setSend};
}

/**
 * @returns {{response: *, setBody: *}}
 */
function removeCartItems(){
    const {response, setBody} = Delete('/user/cart');

    return {response, setBody};
}

/**
 *
 * @returns {{removeItem: *, requestRemoveItem: *}}
 */
function removeCartItem(){
    const {response, setBody, httpStatusCode} = Delete('/user/cart/product/remove');

    return {removeItem: response, requestRemoveItem: setBody, removeItemStatus: httpStatusCode};
}

/**
 * @returns {{saveCart, setSaveCart}}
 */
function saveCart(){
    const {response, setBody, httpStatusCode} = Post('/user/cart/saved-cart');

    return {saveCartResponse: response, setSaveCartBody: setBody, httpStatusCode};
}

export {useAdd2Cart, CartItemAmount, getCartItems, removeCartItems, saveCart, removeCartItem};
