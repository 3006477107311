import React, {useContext, useEffect, useState} from "react";
import config from "../Requests/RequestConfig.json";
import {ChannelContext} from "../Contexts/ChannelContext";
import {useTranslation} from "react-i18next";
import {useCookie} from "./Cookies";
import i18n from "i18next";

interface RequestArgumentsInterface {
    path: string,
    params: {
        method: string
    }
}

interface RequestReturnInterface {
    error: boolean,
    readonly response: any,
    setSend: React.Dispatch<null|boolean>,
    setBody: React.Dispatch<null|object>,
    readonly httpStatusCode: number
}

interface GetInterface {
    error: boolean,
    readonly response: any,
    setSend: React.Dispatch<null|boolean>,
    readonly httpStatusCode: number
}

interface PostInterface {
    error: boolean,
    readonly response: any,
    setBody: React.Dispatch<object|null>,
    readonly httpStatusCode: number
}

interface DeleteInterface extends PostInterface{

}

interface PutInterface extends PostInterface{

}

/**
 * Base request HOOK
 */
function useRequest(settings: RequestArgumentsInterface): RequestReturnInterface{

    const headers                   = settings.params;

    const {i18n}                    = useTranslation();
    const {getCookie}               = useCookie();

    const [response, setResponse]   = useState<null|object>(null);
    const [error, setError]         = useState<boolean>(false);
    const [body, setBody]           = useState<null|object>(null);
    const [send, setSend]           = useState<null|boolean>(null);
    const [httpStatusCode, setHttpStatusCode] = useState<number>(0);
    const context                   = useContext(ChannelContext);
    // const {setCode}                 = useHttpStatusCode();

    /**
     * Check if for methods:
     *
     * GET - state send is not null
     * POST, PUT, DELETE... - state body is not null
     *
     * @returns {boolean}
     */
    function checkFetchSend(): boolean{
        const method = headers.method;

        return !((method === 'GET' && send === null) || (method !== 'GET' && body === null));
    }

    /**
     * Parse JS body object to json string
     */
    function parseBody2String():string|null {
        return (body !== null ? JSON.stringify(body) : null);
    }

    /**
     * Parse response text(string) to JS object
     */
    function parseString2Response(response: string){
        return (response !== '' ? JSON.parse(response) : {});
    }

    /**
     * Get currency code for request
     *
     * @returns {string|undefined}
     */
    function getCurrency(){
        const currencyCode = getCookie('currency-code');

        if (currencyCode === undefined){
            return 'pln';
        }

        return currencyCode;
    }

    useEffect(()=> {
        /** reset state of error and response for re-render component **/
        setError(false);
        // setResponse(null);
        setHttpStatusCode(0)

        if (!checkFetchSend()){
            return;
        }

        let headers2 = {
            'Content-Language': i18n.language, // ok
            'Currency': getCurrency(),
            'Content-Type': 'application/json'
        };

        if (localStorage.getItem('contractor') !== null){
            headers2 = {...headers2, ...{'contractor': localStorage.getItem('contractor')}}
        }

        fetch(config.domain+settings.path,
            {...{
                    credentials: 'include',
                    body: parseBody2String(),
                },
                ...headers,
                ...{
                    headers: headers2
                }
            })
            .then((result)=> {
                /** Check if response is not 2XX code **/
                !result.ok ? setError(true) : setError(false);

                setHttpStatusCode(result.status);

                if (result.status === 401){
                    // if (context?.setUser !== null) {
                    //     context?.setUser(null);
                    // }
                }

                // setCode(result.status);

                return result.text();
            })
            .then((result) => {
                const res = parseString2Response(result);

                setResponse(res);
            });

        /** reset send state for next request **/
        setSend(null);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [body, settings.path, send]);

    return {error, response, setSend, setBody, httpStatusCode};
}

/**
 * Get facade
 */
function Get(path = '', params = {}): GetInterface{
    const {error, response, setSend, httpStatusCode} = useRequest({
        path: path,
        params: {...params, ...{method: "GET"}}});

    return {error, response, setSend, httpStatusCode};
}

/**
 * Post facade
 */
function Post(path = '', params = {}): PostInterface{
    const {error, response, setBody, httpStatusCode} = useRequest({
        path: path,
        params: {...params, ...{method: "POST"}}});

    return {error, response, setBody, httpStatusCode};
}

/**
 * Post facade
 */
function Put(path = '', params = {}): PutInterface{
    const {error, response, setBody, httpStatusCode} = useRequest({
        path: path,
        params: {...params, ...{method: "PUT"}}});

    return {error, response, setBody, httpStatusCode};
}

/**
 * Delete facade
 */
function Delete(path = '', params = {}): DeleteInterface{
    const {error, response, setBody, httpStatusCode} = useRequest({
        path: path,
        params: {...params, ...{method: "DELETE"}}});

    return {error, response, setBody, httpStatusCode};
}

export {useRequest, Get, Post, Put, Delete, type GetInterface, type PostInterface, type DeleteInterface, type PutInterface};
