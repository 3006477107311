import React from "react";
import {UserType} from "../../Types/UserType";
import internal from "stream";

interface SimpleProductInterface{
    ref: any,
    quantityPackage: number,
    unit: string,
    id: number
}

interface SimpleProductsInterface{
    id: number,
    cartItemQuantity: number,
}

class ListItemHelper {
    private _product: SimpleProductInterface|null = null;
    private _products: SimpleProductsInterface[]|null = null;
    private _productsDispatch: React.Dispatch<any>|null = null;
    private _sendProductCart: React.Dispatch<any>|null = null;

    public set product(product: SimpleProductInterface){
        this._product = product;
    }

    public set products(products: SimpleProductsInterface[]){
        this._products = products;
    }

    public set productsDispatch(productsDispatch: React.Dispatch<any>){
        this._productsDispatch = productsDispatch;
    }

    public set sendProductCart(RequestPostDispatch: React.Dispatch<any>){
        this._sendProductCart = RequestPostDispatch;
    }

    public setDependency(dependency: any): void
    {
        this._product           = dependency.product;
        this._products          = dependency.products;
        this._productsDispatch  = dependency.setProducts;
        this._sendProductCart   = dependency.setBody;
    }

    public updateProductCartQuantity(quantity: number): void {
        if (this._products === null){
            throw new Error('Products array (all of list) object must be init before add to cart.');
        }

        this._products.map((productData: SimpleProductsInterface) => {
            if (productData.id === this._product!.id){
                productData.cartItemQuantity = quantity;
            }
        });

        this._productsDispatch!(this._products);
    }

    public addProductCart(quantity: number): void{
        if (this._product === null){
            throw new Error('Product object must be init before add to cart.');
        }

        if (this._products === null){
            throw new Error('Products array (all of list) object must be init before add to cart.');
        }

        if (this._productsDispatch === null){
            throw new Error('Products dispatch setter must be init before add to cart.');
        }

        if (this._sendProductCart === null){
            throw new Error('Request dispatch setter must be init before add to cart.');
        }

        this._sendProductCart({product_id: this._product.id, quantity: quantity});
    }

    public triggerLoginFormIfUserIsNotLogged(notification: string, inputRef: any){
        const loginButton: HTMLElement = document.querySelector('#login-button') as HTMLElement;
        const modalNotificationContainer: HTMLElement = document.querySelector('.login-form .form-notification') as HTMLElement;

        if (this._products === null){
            throw new Error('Products array (all of list) object must be init before add to cart.');
        }

        this._products.map((productData: SimpleProductsInterface) => {
            if (productData.id === this._product!.id){
                productData.cartItemQuantity = 0;
            }
        });

        this._productsDispatch!(this._products);

        inputRef!.current.value = '';

        loginButton.click();
        modalNotificationContainer.innerHTML = '<div class="form-success" >\n' +
            '                \n' +
            '                <span>'+notification+'</span>\n' +
            '            </div>';
    }
}

export {ListItemHelper};