import {flexRender} from "@tanstack/react-table";

function Table({additionalBottom, table}){
    return(
        <>
            <table className="table-data" >
                <thead>
                    <tr>
                        {table.getAllFlatColumns().map((column) => {
                            return <th key={column.id} >{column.columnDef.header}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                {table.getRowModel().flatRows.map((row) => {

                    return(
                        <tr key={row.id} >
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    );
                })}
                </tbody>
            </table>

            {additionalBottom ?? additionalBottom}

            {(table.getPageCount() > 1 ? <Pagination table={table} /> : '' )}
        </>
    );
}

function Pagination({table}){
    const pagesLimitInFrontAndBack = 3;
    const activePage = table.getState().pagination.pageIndex + 1;

    function getStartLoop(){
        const start = activePage - pagesLimitInFrontAndBack;

        if (start <= 1){
            return 1;
        }

        return start;
    }

    function getEndLoop(){
        const end = activePage + pagesLimitInFrontAndBack;

        if (end >= table.getPageCount()){
            return table.getPageCount();
        }

        return end;
    }

    function numbersOfPages(){
        let pagesButton = [];

        if (getStartLoop() > 1){
            pagesButton.push(
                <button
                    className={'number'}
                    onClick={(e) => table.setPageIndex(0)}
                    key={0} >
                    1 ....
                </button>
            );
        }

        for(let page = getStartLoop(); page <= getEndLoop(); page++){
            pagesButton.push(
                <button
                    className={'number'+(activePage === page ? ' active': '')}
                    onClick={(e) => table.setPageIndex(page-1)}
                    key={page} >
                    {page}
                </button>
            );
        }

        if (getEndLoop() < table.getPageCount()){
            pagesButton.push(
                <button
                    className={'number'}
                    onClick={(e) => table.setPageIndex(table.getPageCount()-1)}
                    key={table.getPageCount()} >
                    ... {table.getPageCount()}
                </button>
            );
        }

        return pagesButton;
    }

    return(
        <>
            <div className="table-pagination" >
                <button
                    disabled={!table.getCanPreviousPage()}
                    onClick={() => table.previousPage()}
                    className="prev" >
                    <i className="fas fa-angle-left" ></i>
                </button>

                {numbersOfPages()}

                <button
                    disabled={!table.getCanNextPage()}
                    onClick={() => table.nextPage()}
                    className="next">
                    <i className="fas fa-angle-right" ></i>
                </button>
            </div>
        </>
    );
}

export {Table};
