import {Link} from "react-router-dom";

function Breadcrumb({breadcrumb}){
    return(
        <>
            <div className="breadcrumb" >
                <Link to="/" >Start</Link>
                <i className="fas fa-angle-right" ></i>
                {breadcrumb.map((value, key) => {
                    if (value.path !== null){
                        return(
                            <span key={key}>
                                <Link to={value.path} >{value.name}</Link>
                                {addAngle(key, breadcrumb)}
                            </span>
                        );
                    } else {
                        return(
                            <span key={key}>
                                <span>{value.name}</span>
                                {addAngle(key, breadcrumb)}
                            </span>
                        );
                    }
                })}
            </div>
        </>
    );
}

function addAngle(key, array){
    if (array[key+1] !== undefined){
        return <i className="fas fa-angle-right" ></i>;
    }
}

export default Breadcrumb;
