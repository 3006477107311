import {ProductType} from "../../Types/ProductType";
import React from "react";
import {TFunction} from "react-i18next";
import {Tooltip} from "react-tooltip";

interface IconDesignationInterface {
    getIcons: () => React.ReactElement,
    getIconsAsTest: () => React.ReactElement
}

class IconsDesignation implements IconDesignationInterface
{
    private readonly isMassive:    boolean = false;
    private readonly isSales:      boolean = false;
    private readonly isPromoted:   boolean = false;
    private readonly isNew:        boolean = false;

    private readonly translate: TFunction;

    public constructor(product: ProductType, translate: TFunction<any>) {
        this.isMassive     = product.massive;
        this.isSales       = product.isSales;
        this.isPromoted    = product.promoted;
        this.isNew         = product.isNew;

        this.translate = translate;
    }

    private getMassiveIcon(): React.ReactElement {
        const UUID = crypto.randomUUID();

        return (
            <>
                <i className="fas fa-weight-hanging" id={'weight-product'+UUID} ></i>
                <Tooltip anchorSelect={'#weight-product'+UUID} className={'tooltip-info'} >
                    {this.translate('products-list.product-large-size')}
                </Tooltip>
            </>
        );
    }

    private getSalesIcon(): React.ReactElement {
        return <span className={'sales'} >{this.translate('products-list.sale')}</span>;
    }

    private getPromotedIcon(): React.ReactElement {
        return <span className={'promotion'} >{this.translate('products-list.promotion')}</span>;
    }

    private getNewIcon(): React.ReactElement {
        return <span className={'new'} >{this.translate('products-list.new')}</span>
    }

    public getIcons(): React.ReactElement {
        return(
            <>
                {this.isMassive    && this.getMassiveIcon()}
                {this.isSales      && this.getSalesIcon()}
                {this.isPromoted   && this.getPromotedIcon()}
                {this.isNew        && this.getNewIcon()}
            </>
        );
    }

    public getIconsAsTest(): React.ReactElement {
        return(
            <>
                {this.getMassiveIcon()}
                {this.getSalesIcon()}
                {this.getPromotedIcon()}
                {this.getNewIcon()}
            </>
        );
    }
}

export {IconsDesignation, type IconDesignationInterface};