import {UseFormReturn} from "react-hook-form";
import {getRawData} from "../../Hooks/FormDataHelper";
import {getCookie} from "../../Hooks/Cookies";
import {LoaderFunctionArgs, Params} from "react-router-dom";

const menuFilters = [
    'producers',
    'brands',
    'licenses',
    'categories'
];

const searchFilters = [
    'search',
    'name',
    'ean',
    'index_hurt',
    'index_producer',
    'description',
    'indexes_hurt',
    'eans',
    'indexes_producer',
    'inDescription'
];

function buildLoaderRequest(params: Params, requestUrl: string){
    const url = new URL(requestUrl);
    let buildRequest: any = {};

    for (const param of url.searchParams){
        buildRequest[param[0]] = (menuFilters.indexOf(param[0]) !== -1 ? param[1].split(',') : param[1]);
    }

    if (params.id){
        buildRequest[params.filter!] = (
            menuFilters.indexOf(params.filter!) !== -1 ? [params.id] : params.id
        );
    } else {
        buildRequest[params.filter!] = true;
    }

    buildRequest['per-page'] = (getCookie('per-page') ? parseInt(getCookie('per-page')) : 30);
    buildRequest['sort']     = (getCookie('sort') ? parseInt(getCookie('sort')) : 0);

    buildRequest['menuitem'] = params.filter;

    return buildRequest;
}


function mappingURLtoForm(filter: {name: string | undefined, id: string | undefined}){
    let filterName = filter.name || '';
    let filterId = filter.id || 0;

    const searchParams = new URLSearchParams(window.location.search);
    let buildParamsObject: any = {};

    for(const param of searchParams){
        buildParamsObject[param[0]] = (menuFilters.indexOf(param[0]) !== -1 ? param[1].split(',') : param[1]);
    }

    buildParamsObject[filterName] = filterId;

    return buildParamsObject;
}

function getSearchFilterFromURL(){
    const url = new URL(window.location.href);
    const buildObject: any = {};

    for (const [key, value] of url.searchParams.entries()){
        if (searchFilters.includes(key)){
            buildObject[key] = value;
        }
    }

    return buildObject;
}

function collectDataForm(form: UseFormReturn, filter: {name: string | undefined, id: string | undefined}, setSearchParams: any){
    let filterName = filter.name || '';
    let filterId = filter.id || 0;

    const searchFiltersData = getSearchFilterFromURL();
    const rawData = getRawData(form.watch(), filterName);

    const url = new URL(window.location.origin+window.location.pathname);



    const dataParams = new URLSearchParams({...searchFiltersData, ...rawData});

    for (const [key, value] of dataParams.entries()){
        url.searchParams.set(key, value);
    }

    setSearchParams(dataParams);
}

export {mappingURLtoForm, collectDataForm, buildLoaderRequest, getSearchFilterFromURL};
