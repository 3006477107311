import {useTranslation} from "react-i18next";
import {ListItemHelper} from "../ListItemHelper";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {UserContext} from "../../../Contexts/UserContext";
import {debounce} from 'lodash';
import {ChannelContext} from "../../../Contexts/ChannelContext";

function QuantityFields({data, label, revers}: {data: any, label: boolean, revers?: boolean|undefined}){
    const {t}               = useTranslation();
    const listItemHelper    = useMemo(() => new ListItemHelper(), []);
    const {user}            = useContext<any>(UserContext);
    const channelContext    = useContext(ChannelContext);
    const inputRef          = useRef<any>();
    const [quantityPackage, setQuantityPackage] = useState(data.product.cartItemQuantity);

    listItemHelper.setDependency({ product: data.product, products: data.products, setProducts: data.setProducts, setBody: data.setBody });

    useEffect(() => {
        inputRef.current.value = data.product.cartItemQuantity === 0 ? '' : data.product.cartItemQuantity;
    }, []);

    useEffect(() => {
        if (user === false){
            inputRef.current.value = '';
            setQuantityPackage(0);
        } else {
            inputRef.current.value = data.product.cartItemQuantity === 0 ? '' : data.product.cartItemQuantity;
            setQuantityPackage(data.product.cartItemQuantity === 0 ? '' : data.product.cartItemQuantity);
        }
    }, [user]);

    const handleInputDebounced = useCallback(debounce((value) => {
        listItemHelper.addProductCart(value);
    }, 1000), [data, listItemHelper, user, t]);

    function calculatedPackage(id: number): string {

        let exProducts: number[] = [];

        channelContext?.products.map((product) => {
            exProducts.push(product.id);
        });

        if (channelContext?.IncompletePackaging === true && !exProducts.includes(data.product.id)){
            return quantityPackage;
        }

        return (quantityPackage ? Number(quantityPackage * data.product.quantityPackage).toFixed(0)+' '+ data.product.unit : '');
    }

    if (revers === true){
        return(
            <>
                <QuantityField label={label} inputRef={inputRef} listItemHelper={listItemHelper} setQuantityPackage={setQuantityPackage} handleInputDebounced={handleInputDebounced} />
                <QuantityPackageField calculatedPackage={calculatedPackage} inputRef={data.product.ref} />
            </>
        );
    } else {
        return(
            <>
                <QuantityPackageField calculatedPackage={calculatedPackage} inputRef={data.product.ref} />
                <QuantityField label={label} inputRef={inputRef} listItemHelper={listItemHelper} setQuantityPackage={setQuantityPackage} handleInputDebounced={handleInputDebounced} />
            </>
        );
    }
}

function QuantityPackageField({calculatedPackage, inputRef}: any): React.ReactElement {
    return (
        <div className="field" >
            <input
                type="text"
                name="quantity-package"
                disabled={true}
                value={calculatedPackage()}
                ref={inputRef} />
        </div>
    );
}

function QuantityField({label, inputRef, listItemHelper, setQuantityPackage, handleInputDebounced}: any): React.ReactElement {
    const {t} = useTranslation();
    const {user} = useContext<any>(UserContext);
    const [ignoreInput, setIgnoreInput] = useState<boolean>(false);

    return (
        <div className="field" >
            {label ? <span>{t('products-list.quantity')+': '}</span> : ''}

            <input
                type="number"
                name="quantity"
                step={1}
                min="0"
                ref={inputRef}
                onWheel={(event) => {
                    event.currentTarget.blur();
                    event.preventDefault();
                    return false;
                }}
                onWheelCapture={(event) => {
                    event.currentTarget.blur();
                    event.preventDefault();
                    return false;
                }}
                onKeyDown={(event) => {
                    const inputs = document.querySelectorAll('.list .product input[name="quantity"]') as NodeListOf<HTMLInputElement>;
                    const currentIndex = Array.from(inputs).indexOf(event.currentTarget);

                    if (event.key === 'ArrowDown') {
                        if (currentIndex >= 0 && currentIndex < inputs.length - 1) {
                            inputs[currentIndex + 1].focus();
                        }

                        event.preventDefault();
                    }

                    if (event.key === 'ArrowUp'){
                        if (currentIndex !== 0) {
                            inputs[currentIndex - 1].focus();
                        }

                        event.preventDefault();
                    }
                }}
                onInput={(e: any) => {
                    if (user === false){
                        listItemHelper.triggerLoginFormIfUserIsNotLogged(t('layout.login-form.buy-not-logged'), inputRef);
                        e.preventDefault();
                    }

                    setQuantityPackage(e.currentTarget.value);
                    listItemHelper.updateProductCartQuantity(e.currentTarget.value);
                    handleInputDebounced(e.currentTarget.value);
                }} />
        </div>
    );
}

export {QuantityFields};