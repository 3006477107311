import {useContext, useEffect, useState} from "react";
import {ForceReloadContext} from "../Contexts/ForceReloadContext";
import {Link} from "react-router-dom";

function useForceReload(callback: CallableFunction){
    const forceReloadViewContext = useContext(ForceReloadContext);

    useEffect(() => {
        if (forceReloadViewContext?.forceReloadView === 'login' || forceReloadViewContext?.forceReloadView === 'logout') {

            callback();
            forceReloadViewContext.setForceReloadView(null);
        }
    }, [forceReloadViewContext?.forceReloadView]);
}

export {useForceReload}