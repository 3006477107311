interface DataInterface{
    id: number,
    name: string,
    children: object[]
}

class SearchInFilter{
    private readonly findableString: string;
    private readonly data: DataInterface[];
    private results: object[];

    constructor(findableString: string, data: DataInterface[]) {
        this.findableString = findableString ? findableString.toLowerCase() : '';
        this.data = data;
        this.results = [];
    }

    private getLetter(): any{
        return this.findableString[0].toLowerCase();
    }

    private find(value: any): boolean{
        if (!value.hasOwnProperty('name')){
            throw new Error('Property "name" of data object in search in filter not found. Check data provider.');
        }

        return value.name.toLowerCase().includes(this.findableString.toLowerCase());
    }

    private search(){
        Object.keys(this.data).map((value: any) => {
            this.data[value].children.map((value2: any) => {
                if (this.find(value2)){
                    this.results = [...this.results, value2];
                }
            });
        });
    }

    public getResults(): object[]|null{
        if (this.findableString !== null && this.findableString !== ''){
            this.search();

            return this.results;
        }

        return null;
    }
}

export {SearchInFilter};