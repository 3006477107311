import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from "i18next-http-backend";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
    .use(HttpApi)
    .use(initReactI18next)
    // .use(Backend)
    // .use(LanguageDetector)
    .init({
        react: {
                useSuspense: false
        },
        fallbackLng: 'en-GB',
            backend: {
                    loadPath: '/locales/{{lng}}/translation.json',
            },
        supportedLngs: ['pl-PL', 'en-GB', 'de-DE']
});
