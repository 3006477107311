import Filter from "../../Components/Filter";
import {useFormContext} from "react-hook-form";
import {Link, ScrollRestoration, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Tooltip} from "react-tooltip";

/**
 * Products list filters
 */
function Filters({filters, send, resetAllForm}){
    const form            = useFormContext();
    const {filter}        = useParams();
    const {t}             = useTranslation();

    return(
        <>
            <div className="filters" >
                <form onSubmit={form.handleSubmit(send)} name="filters" >
                    {filter === 'categories' ? <CategoriesLinks data={filters.categories} /> : <CategoriesCheckbox data={filters.categories} onChange={send} />}
                    {filter !== 'news' && <New data={filters.new} onChange={send} />}
                    {filter !== 'sales' && <Sale data={filters.sale} onChange={send} />}
                    {/*<Advertisement onChange={send} />*/}
                    <Quantity data={filters.quantity} onChange={send} />
                    {filter !== 'producers' && filter !== 'brands' && <Producers data={filters.producers} onChange={send} />}
                    {filter !== 'brands' && <Brands data={filters.brands} onChange={send} />}
                    {filter !== 'licenses' && <Licenses data={filters.licenses} onChange={send} />}
                    {filter !== 'promotions' && 'promotion' in filters && <Promotion data={filters.promotion} onChange={send} />}
                    <Price onChange={send} />

                    <div className="reset" >
                        <button type="reset" className="btn" onClick={resetAllForm} >{t('layout.filters.reset-all-filters')}</button>
                    </div>
                </form>
            </div>
        </>
    );
}

/**
 * Filters categories as URL link
 */
function CategoriesLinks({data}){

    const {filter}    = useParams();
    const {t}           = useTranslation();
    let path, params;

    if (Object.values(data).length > 0){
        return(
            <Filter name={t('layout.menu.categories')} >
                {(Object.values(data).map((value, key) => {
                    if (filter === 'categories'){
                        path = "/products-list/categories/"+value['id'];
                    } else {
                        params = new URLSearchParams(window.location.search);
                        params.set('categories', value['id']);

                        path = window.location.pathname+'?'+params.toString();
                    }

                    return (
                        <div className="filter-item" key={key} >
                            {(value['products_quantity'] > 0) ?
                                <Link to={path} >
                                    <i className="fas fa-angle-right" ></i>
                                    <span>{value['hurt_name']} <span className={'products-quantity'}>({value['products_quantity']})</span></span>
                                </Link>
                                :
                                <div>
                                    <i className="fas fa-angle-right" ></i>
                                    <span>{value['hurt_name']} <span className={'products-quantity'}>({value['products_quantity']})</span></span>
                                </div>
                            }
                        </div>
                    );
                }))}
            </Filter>
        );
    }

    return <></>;
}

/**
 * Categories as checkboxes
 */
function CategoriesCheckbox({data, onChange}){
    const {register, getValues} = useFormContext();
    const {t}        = useTranslation();
    let filterVisible = false;

    if (Array.isArray(getValues().categories) === true){
        filterVisible = true;
    }

    if (Object.values(data).length > 0){
        return(
            <Filter name={t('layout.menu.categories')} resetVisible={filterVisible} filterInputName={['categories']} >
                {(Object.values(data).map((value, key) => {
                    return (
                        <div className={'filter-item'+(value.products_quantity === 0 ? ' disabled' : '')} key={value.id} >
                            <input
                                type="checkbox"
                                disabled={(value.products_quantity === 0 ? 'disabled' : null)}
                                {...register("categories[]", {onChange: onChange})}
                                value={value.id} />
                            <span>
                                <span className="name" >{value.hurt_name}</span>
                                <span className="products-quantity" > ({value.products_quantity})</span>
                            </span>
                        </div>
                    );
                }))}
            </Filter>
        );
    }

    return <></>;
}

/**
 * Filter - new products
 */
function New({data, onChange}){
    const {register, getValues} = useFormContext();
    const {t}        = useTranslation();
    let filterVisible = false;

    if (getValues().news === 'true'){
        filterVisible = true;
    }

    return (
        <Filter name={t('layout.menu.news')} send={onChange} resetVisible={filterVisible} filterInputName={['news']} >
            <div className={'filter-item'+(data.is_new === false ? ' disabled' : '')} >
                <input
                    type="checkbox"
                    disabled={(data.is_new === false ? 'disabled' : null)}
                    {...register("news", {onChange: onChange})} />
                <span>
                    <span className="name" >{t('layout.filters.only-new')}</span>
                    <span className="products-quantity" > ({data.products_quantity})</span>
                </span>
            </div>
        </Filter>
    );
}

/**
 * Filter - sales products
 */
function Sale({data, onChange}){
    const {register, getValues} = useFormContext();
    const {t}        = useTranslation();
    let filterVisible = false;

    if (getValues().sales === 'true'){
        filterVisible = true;
    }

    return (
        <Filter name={t('layout.menu.sales')} send={onChange} resetVisible={filterVisible} filterInputName={['sales']} >
            <div className={'filter-item'+(data.is_sale === false ? ' disabled' : '')} >
                <input
                    type="checkbox"
                    disabled={(data.is_sale === false ? 'disabled' : null)}
                    {...register("sales", {onChange: onChange})} />
                <span>
                    <span className="name" >{t('layout.filters.only-sales')}</span>
                    <span className="products-quantity" > ({data.products_quantity})</span>
                </span>
            </div>
        </Filter>
    );
}

/**
 * Filters products in ad
 */
function Advertisement({onChange}){
    const {register, getValues} = useFormContext();
    const {t}        = useTranslation();
    let filterVisible = false;

    if (typeof getValues().advertisement !== 'undefined'){
        filterVisible = true;
    }

    return (
        <Filter name={t('layout.filters.ad')} send={onChange} resetVisible={filterVisible} filterInputName={['advertisement']} >
            <div className="filter-item" >
                <input type="checkbox" {...register("advertisement", {onChange: onChange})} />
                <span>{t('layout.filters.only-ad')}</span>
            </div>
        </Filter>
    );
}

/**
 * Filter quantity
 */
function Quantity({data, onChange}){
    const {register, getValues} = useFormContext();
    const {t}        = useTranslation();
    let filterVisible = false;

    if (getValues().quantity){
        filterVisible = true;
    }

    return(
        <Filter name={t('layout.filters.quantity')} send={onChange} resetVisible={filterVisible} filterInputName={['quantity']} >
            {(Object.values(data).map((value, key)=>{
                return (
                    <div className={"filter-item"+(value.products_quantity === 0 ? ' disabled' : '')} key={value.value} >
                        <input
                            type="radio"
                            disabled={(value.products_quantity === 0 ? 'disabled' : null)}
                            {...register("quantity", {onChange: onChange})}
                            value={value.value} />
                        <span>
                            <span className="name" >
                                {(key === 0 ? t('layout.filters.all-quantity') : t('layout.filters.more-than')+' '+value.value )}
                            </span>
                            <span className="products-quantity" > ({value.products_quantity})</span>
                        </span>
                    </div>
                );
            }))}
        </Filter>
    );
}

/**
 * Filter producers
 */
function Producers({data, onChange}){
    const {register, getValues} = useFormContext();
    const {t}        = useTranslation();
    let filterVisible = false;

    if (getValues().producers?.length > 0){
        filterVisible = true;
    }

    return(
        <Filter name={t('layout.menu.producers')} send={onChange} resetVisible={filterVisible} filterInputName={['producers']} >
            {(Object.values(data).map((value, key)=>{
                return (
                    <div className={"filter-item"+(value.products_quantity === 0 ? ' disabled' : '')} key={value.id} >
                        <input
                            type="checkbox"
                            disabled={(value.products_quantity === 0 ? 'disabled' : null)}
                            {...register("producers[]", {onChange: onChange})}
                            value={value.id} />
                        <span>
                            <span className="name" >{value.name}</span>
                            <span className="products-quantity" > ({value.products_quantity})</span>
                        </span>
                    </div>
                );
            }))}
        </Filter>
    );
}

/**
 * Filter brands
 */
function Brands({data, onChange}){
    const {register, getValues} = useFormContext();
    const {t}        = useTranslation();
    let filterVisible = false;

    if (getValues().brands?.length > 0){
        filterVisible = true;
    }

    return(
        <Filter name={t('layout.menu.brands')} send={onChange} resetVisible={filterVisible} filterInputName={['brands']} >
            {(Object.values(data).map((value, key)=>{
                return (
                    <div className={"filter-item"+(value.products_quantity === 0 ? ' disabled' : '')} key={value.id} >
                        <input
                            type="checkbox"
                            data-parent-id={(value.hasOwnProperty('parent_id') ? value.parent_id : '')}
                            disabled={(value.products_quantity === 0 ? 'disabled' : null)}
                            {...register("brands[]", {onChange: onChange})}
                            value={value.id} />
                        <span>
                            <span className="name" >{value.name}</span>
                            <span className="products-quantity" > ({value.products_quantity})</span>
                        </span>
                    </div>
                );
            }))}
        </Filter>
    );
}

/**
 * Filter licenses
 */
function Licenses({data, onChange}){
    const {register, getValues} = useFormContext();
    const {t}        = useTranslation();
    let filterVisible = false;

    if (getValues().licenses?.length > 0){
        filterVisible = true;
    }

    return(
        <Filter name={t('layout.menu.licenses')} send={onChange} resetVisible={filterVisible} filterInputName={['licenses']} >
            {(Object.values(data).map((value, key)=>{
                return (
                    <div className={"filter-item"+(value.products_quantity === 0 ? ' disabled' : '')} key={value.id} >
                        <input
                            type="checkbox"
                            disabled={(value.products_quantity === 0 ? 'disabled' : null)}
                            {...register("licenses[]", {onChange: onChange})}
                            value={value.id} />
                        <span>
                            <span className="name" >{value.name}</span>
                            <span className="products-quantity" > ({value.products_quantity})</span>
                        </span>
                    </div>
                );
            }))}
        </Filter>
    );
}

/**
 * Filter - promotion products
 */
function Promotion({data, onChange}){
    const {register, getValues} = useFormContext();
    const {t}        = useTranslation();
    let filterVisible = false;

    if (getValues().promotions === "true"){
        filterVisible = true;
    }


    return (
        <Filter name={t('layout.menu.promotions')} send={onChange} resetVisible={filterVisible} filterInputName={['promotions']}  >
            <div className={"filter-item"+(data.products_quantity === 0 ? ' disabled' : '')} >
                <input
                    type="checkbox"
                    disabled={(data.products_quantity === 0 ? 'disabled' : null)}
                    {...register("promotions", {onChange: onChange})} />
                <span>
                    <span className="name" >{t('layout.filters.only-promoted')}</span>
                    <span className="products-quantity" > ({data.products_quantity})</span>
                </span>
            </div>
        </Filter>
    );
}

/**
 * Filter price range
 */
function Price({onChange}){
    const {register, getValues, watch} = useFormContext();
    const [validatePrice, setValidatePrice] = useState(true)
    const {t}        = useTranslation();
    const priceMin = parseInt(getValues().price_min) || 0;
    const priceMax = parseInt(getValues().price_max) || 0;
    let filterVisible = false;

    if (priceMin > 0 || priceMax > 0){
        filterVisible = true;
    }

    useEffect(() => {
        const priceMin = parseFloat(getValues('price_min'));
        const priceMax = parseFloat(getValues('price_max'));

        if (priceMax < priceMin && priceMin > 0 && priceMax > 0){
            setValidatePrice(false);
        } else {
            setValidatePrice(true);
        }

    }, [watch('price_min'), watch('price_max')]);


    return(
        <Filter name={t('layout.filters.price')} send={onChange} resetVisible={filterVisible} filterInputName={['price_min', 'price_max']} >
            <div className="prices" >
                <input
                    type="number"
                    min="0"
                    step={0.01}
                    {...register("price_min")}
                    placeholder={t('layout.filters.from')}
                    onWheel={(event) => {
                        event.preventDefault();
                        return false;
                    }}

                    onWheelCapture={(event) => {
                        event.preventDefault();
                        return false;
                    }}
                />
                <i className="fas fa-minus"></i>
                <input
                    type="number"
                    min="0"
                    step={0.01}
                    {...register("price_max")}
                    placeholder={t('layout.filters.to')}
                    onWheel={(event) => {
                        event.preventDefault();
                        return false;
                    }}

                    onWheelCapture={(event) => {
                        event.preventDefault();
                        return false;
                    }} />
            </div>

            <div className={'price-filter-button'} >
                {validatePrice === true ?
                    <button className="btn" onClick={onChange} > {t('layout.filters.filter-price')}</button>
                    :
                    <>
                        <button className="btn" type={'button'} id={'disabled-price-button'} > {t('layout.filters.filter-price')}</button>
                        <Tooltip anchorSelect={'#disabled-price-button'} className={'tooltip-info price-button'} place={'bottom'} >
                            {t('products-list.disabled-price-button')}
                        </Tooltip>
                    </>
                }

            </div>
        </Filter>
    );
}

export default Filters;
