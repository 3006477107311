import {useTranslation} from "react-i18next";

function UserIsNotLogged(){
    const {t} = useTranslation();

    return(
        <div className="ui message" >
            {t('layout.ui.messages.user-is-not-logged')}
        </div>
    );
}

export {UserIsNotLogged};
