import React, {useEffect, useState} from "react";
import HeaderTop from "./HeaderTop";
import {HeaderMiddle} from "./HeaderMiddle";
import HeaderMenu from "./HeaderMenu";
import {HeaderScroll, headerScrollVisibleToggle} from "./HeaderScroll";
import {MenuFilters} from "../../Requests/Filters";
import {useForceReload} from "../../Hooks/ForceReload";
import {HeaderContext} from "../../Contexts/HeaderContext";

/**
 * Header is the base component of all top of the page features, like a
 * change currency, change language, login button, search bar, logo and more.
 */
function Header() {
    const [menuVisibleState, setMenuVisibleState] = useState(false);
    const menuFilters = MenuFilters();

    useForceReload(() => menuFilters.getFilters(true));

    useEffect(() => {
        if (menuFilters.filters === null){
            menuFilters.getFilters(true);
        }
    }, []);

    headerScrollVisibleToggle(setMenuVisibleState, 150);

    return(
        <HeaderContext.Provider value={{...menuFilters}}>
            <HeaderScroll />
            <header>
                <HeaderTop/>
                <HeaderMiddle />
                <HeaderMenu/>
            </header>
        </HeaderContext.Provider>
    );
}

export default Header;
