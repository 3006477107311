import {Link, useLoaderData, useParams} from "react-router-dom";
import Breadcrumb from "../../Components/Breadcrumb";
import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useRef, useState} from "react";
import "../../Assets/Css/global.css";
import '../../Assets/Css/Pages/ProductsList/products_list.css'
import '../../Assets/Css/Pages/Offer/direct_offer.css'


// import Product from "../Product/Product";
import {UserContext} from "../../Contexts/UserContext";
import {CartContext} from "../../Contexts/CartContext";
import {ProductsListContext} from "../../Contexts/ProductsListContext";
import {ChannelContext} from "../../Contexts/ChannelContext";
import {useAdd2Cart, useProductData} from "../ProductsList/ProductData";
import {SimpleModal, useSimpleModalState} from "../../Components/SimpleModal";
import {IconsDesignation} from "../ProductsList/IconsDesignation";
import {QuantityDisposition} from "../ProductsList/Components/QuantityDisposition";
import {QuantityFields} from "../ProductsList/Components/QuantityFields";
import config from "../../Requests/RequestConfig.json";
// @ts-ignore
import imageNotFound from "../../Assets/Images/image-not-found.png";
// @ts-ignore
import logo from "../../Assets/Images/logo.png";
// @ts-ignore
import offerLogos from "../../Assets/Images/offer_logos.png";
import {POST} from "../../Hooks/RequestV2";
import Barcode from "react-barcode";
import moment, {Moment} from "moment";

interface OfferInterface {
    id: number,
    name: string,
    dateEnd: string,
    dateStart: string,
    comment: string,
    contractor: string,
    offerItems: {
        id: number,
        price: number,
        discount: number,
        discountPrice: number,
        quantityOffer: number,
        comments: string,
        product: object
    }[]
}

const defaultData: OfferInterface = {
    id: 0,
    name: '',
    dateEnd: '',
    dateStart: '',
    comment: '',
    contractor: '',
    offerItems: []
}

interface FormStateInterface {
    token: string,
    offerItems: { [product_id: number]: number };
}

function DirectOffer(){
    const loader: any           = useLoaderData();
    const {token}               = useParams();
    const [offer, setOffer]     = useState<OfferInterface>(loader ?? defaultData);
    const [formState, setFormState] = useState<FormStateInterface>({token: token ?? '', offerItems: {}});

    useEffect(() => {
        console.debug(formState)
    }, [formState]);

    function sendDataAndGeneratedXlsFile(): void {
        POST('/offer/link', formState)
            .then((response) => {
                if (response.ok){
                    return response.blob();
                }
            })
            .then((blob: any) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');

                let name = 'Oferta nr ' + offer.id;

                a.href = url;
                a.download = name + '.xls';

                document.body.appendChild(a);

                a.click();

                document.body.removeChild(a);
            });
    }

    return(
        <>
            <div className={'direct-offer'} >
                <OfferHeader offer={offer} />

                <div className={'generated-button'} >
                    <button className={'btn'} onClick={() => sendDataAndGeneratedXlsFile()} >Generuj plik XLS</button>
                </div>

                <div className={'products-list'} >
                    <div className={'list'} >
                        <div className={'products'} >
                            <div className="matrix">
                                {offer.offerItems.map((product: object) => {
                                    return <Product data={product} offer={offer} setFormState={setFormState} />
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'generated-button'} >
                    <button className={'btn'} onClick={() => sendDataAndGeneratedXlsFile()} >Generuj plik XLS</button>
                </div>
            </div>
        </>
    );
}

function OfferHeader({offer}: {offer: OfferInterface}){
    return(
        <div className={'header'} >
            <div>
                <img src={logo} className={'logo'} />
                <img src={offerLogos} className={'other-logos'} />
            </div>

            <div className={'base-desc'} >
                <div className={'attr'}>
                    <span>Nazwa Oferty</span>
                    <span>{offer.name}</span>
                </div>
                <div className={'attr'}>
                    <span>Data zakończenia oferty</span>
                    <span>{offer.dateEnd}</span>
                </div>
                {offer.contractor ?

                    <div className={'attr'}>
                        <span>Nazwa kontrahenta</span>
                        <span>{offer.contractor}</span>
                    </div>

                    : null}
                {offer.comment ?

                    <div className={'attr'}>
                        <span>Komentarz do oferty</span>
                        <span>{offer.comment}</span>
                    </div>

                    : null}
            </div>
        </div>
    );
}

function Product({data, offer, setFormState}: any): React.ReactElement {
    const {t}           = useTranslation();
    const imageModal    = useSimpleModalState();
    const descModal     = useSimpleModalState();
    const inputRef      = useRef<HTMLInputElement | null>(null);
    const [quantityPackage, setQuantityPackage] = useState<number>(0);

    useEffect(() => {
        if (inputRef.current && quantityPackage !== 0){
            inputRef.current.value = calculatedPackage();
        }
    }, [quantityPackage]);

    function displayPrice() {
        return (
            <div className="prices" >
                <div className="price-title" >
                    {offer.options.normalPrice === true ?

                        <div className="old-price" >{data.price.toFixed(2)} {t('products-list.before-tax')}</div>

                        : null}

                    {offer.options.discount === true ?
                        <div className="price-discount" >
                            Rabat: {data.discount}
                        </div>
                        : null }
                </div>

                {offer.options.discountPrice === true ?

                    <div className="price-wrapper" >
                        <div className="netto" >{data.discountPrice.toFixed(2)} {t('products-list.before-tax')}</div>
                    </div>

                    : null}

            </div>
        );
    }

    function displayQuantity() {
        return (
            <div className="quantity" >
                <span>{t('products-list.avail')}:</span>
                <span>{data.quantity} {data.ESProductData.unit.toUpperCase()}</span>
            </div>
        );
    }

    function calculatedPackage(): string {
        let quantity: number = 0;

        if (offer.calculatedType === 'package'){
            quantity = Number(Number(quantityPackage * data.ESProductData.quantityInPackage).toFixed(0));
        } else if (offer.calculatedType === 'pieces') {
            quantity = quantityPackage;
        } else {
            if (!offer.productsExceptionsIds.includes(data.ESProductData.id)){
                quantity = quantityPackage;
            } else {
                quantity = Number(Number(quantityPackage * data.ESProductData.quantityInPackage).toFixed(0));
            }
        }

        setFormState((prev: FormStateInterface) => {
            const productId = data.ESProductData.id;

            // Sprawdzamy, czy produkt już istnieje w obiekcie offerItems
            const existingQuantity = prev.offerItems[productId];

            if (existingQuantity !== undefined) {
                if (quantity === 0) {
                    console.debug('Quantity is 0, removing item');

                    // Tworzymy nowy obiekt bez danego product_id
                    const { [productId]: _, ...updatedItems } = prev.offerItems;

                    return {
                        ...prev,
                        offerItems: updatedItems, // Zaktualizowany obiekt bez produktu
                    };
                } else {
                    console.debug('Item already exists, updating it');

                    // Aktualizujemy ilość dla istniejącego product_id
                    return {
                        ...prev,
                        offerItems: {
                            ...prev.offerItems,
                            [productId]: quantity, // Zaktualizowana ilość
                        },
                    };
                }
            } else {
                if (quantity > 0) {
                    console.debug('Item does not exist, adding it');

                    // Dodajemy nowy produkt do obiektu offerItems
                    return {
                        ...prev,
                        offerItems: {
                            ...prev.offerItems,
                            [productId]: quantity, // Nowy produkt z ilością
                        },
                    };
                } else {
                    console.debug('Quantity is 0, nothing to add');
                    return prev; // Jeśli ilość jest 0 i element nie istnieje, nie robimy nic
                }
            }
        });

        return quantity+' '+data.ESProductData.unit;
    }

    return (
        <section className="product" >
            {offer.options.thumb === true ?
                <div
                    className="thumb"
                    style={{backgroundImage: 'url('+(data.ESProductData.thumb ? config.domain+'/assets/img/thumbnail/'+data.ESProductData.thumb : imageNotFound+')')}}
                    onClick={() => {

                        if (data.ESProductData.thumb){
                            imageModal.toggleVisibleModal();
                        }
                    }} >

                    {data.ESProductData.thumb ? <i className="fas fa-search-plus more" /> : null}
                </div>
                : null}

            <SimpleModal visibility={imageModal.visibility} hidden={imageModal.toggleVisibleModal} className={'product-thumb-modal'} wait={false} >
                <img src={(data.ESProductData.thumb ? config.domain+'/assets/img/'+data.ESProductData.thumb : imageNotFound+')')} className={'thumb-img'} />
            </SimpleModal>

            <SimpleModal visibility={descModal.visibility} hidden={descModal.toggleVisibleModal} className={'product-thumb-modal'} wait={false} >
                <div dangerouslySetInnerHTML={{__html: data.ESProductData.description[4]}} ></div>
            </SimpleModal>

            {offer.options.productName === true ?

                <Link to={'/product/'+data.ESProductData.slug} >
                    <h2>{data.ESProductData.name[4]}</h2>
                </Link>

                : null}


            {displayPrice()}
            <div className="attributes" >
                {offer.options.index === true ?

                    <div className="index" >
                        <span>Index ET:</span>
                        <span>{data.ESProductData.index_hurt}</span>
                    </div>

                    : null }

                {offer.options.ean === true ?
                    <div className="ean" >
                        <span>{t('products-list.ean')}:</span>
                        <span>{data.ESProductData.default_ean !== null ? data.ESProductData.default_ean : '-'}</span>
                    </div>
                    : null}

                {offer.options.offerQuantity === true ?

                    displayQuantity()

                    : null}


                {offer.options.package === true ?

                    <div className="package" >
                        <span>{t('products-list.in-package')}:</span>
                        <span>{data.ESProductData.quantityInPackage}</span>
                    </div>

                    : null}


                {offer.options.producer === true ?

                    <div className="package" >
                        <span>Producent:</span>
                        <span>{data.ESProductData.producer.name}</span>
                    </div>

                    : null}

            </div>
            <div className="attributes" >
                {offer.options.moqHurt === true ?

                    <div className="index" >
                        <span>MOQ:</span>
                        <span>{data.ESProductData.moq_hurt}</span>
                    </div>

                    : null}

                {offer.options.moqShop === true ?

                    <div className="index" >
                        <span>MOQ:</span>
                        <span>{data.ESProductData.moq_shop}</span>
                    </div>

                    : null}

                {offer.options.packageOffer === true ?
                    <div className="ean" >
                        <span>Pakowanie:</span>
                        <span>{data.package ?? '-'}</span>
                    </div>

                    : null}

                {offer.options.brand === true ?

                    <div className="package" >
                        <span>Brand:</span>
                        <span>{data.ESProductData.brand.name}</span>
                    </div>

                    : null}

                {offer.options.license === true ?

                    <div className="package" >
                        <span>Licencja:</span>
                        <span>{data.ESProductData.license?.name}</span>
                    </div>

                    : null}

                {offer.options.desc === true ?

                    <div>
                        <button onClick={() => descModal.toggleVisibleModal()} >
                            <i className={'fas fa-search-plus'} /> Opis
                        </button>
                    </div>

                    : null}

            </div>

            {offer.options.ean === true && data.ESProductData.default_ean !== null ?
                <div className={'barcode'} >
                    <Barcode value={data.ESProductData.default_ean} />
                </div>
                : null}


            {offer.options.remarks === true ?

                <div className={'comment'} >{data.comments}</div>

                : null}


            <div className="add-to-cart" >
                <div className="field" >
                    <input
                        type="text"
                        name="quantity-package"
                        disabled={true}
                        // value={calculatedPackage()}
                        ref={inputRef}
                    />
                </div>
                <div className="field" >
                    <span>{t('products-list.quantity')+': '}</span>

                    <input
                        type="number"
                        name="quantity"
                        step={1}
                        min="0"
                        // ref={inputRef}
                        onWheel={(event) => {
                            event.currentTarget.blur();
                            event.preventDefault();
                            return false;
                        }}
                        onWheelCapture={(event) => {
                            event.currentTarget.blur();
                            event.preventDefault();
                            return false;
                        }}
                        onKeyDown={(event) => {
                            const inputs = document.querySelectorAll('.list .product input[name="quantity"]') as NodeListOf<HTMLInputElement>;
                            const currentIndex = Array.from(inputs).indexOf(event.currentTarget);

                            if (event.key === 'ArrowDown') {
                                if (currentIndex >= 0 && currentIndex < inputs.length - 1) {
                                    inputs[currentIndex + 1].focus();
                                }

                                event.preventDefault();
                            }

                            if (event.key === 'ArrowUp'){
                                if (currentIndex !== 0) {
                                    inputs[currentIndex - 1].focus();
                                }

                                event.preventDefault();
                            }
                        }}
                        onInput={(e: any) => {
                            setQuantityPackage(e.currentTarget.value);
                        }} />
                </div>
            </div>

        </section>
    );
}

export {DirectOffer};