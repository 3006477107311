import {ProductsListContext} from "../../../Contexts/ProductsListContext";
import React, {useContext, useEffect, useState} from "react";
import {CartContext} from "../../../Contexts/CartContext";
import {useProductData} from "../ProductData";
import {useTranslation} from "react-i18next";
import {SimpleModal, useSimpleModalState} from "../../../Components/SimpleModal";
import {IconsDesignation} from "../IconsDesignation";
import {Link} from "react-router-dom";
import {QuantityDisposition} from "../Components/QuantityDisposition";
import {QuantityFields} from "../Components/QuantityFields";
import {ProductInterface} from "../DataInterface";
import {POST} from "../../../Hooks/RequestV2";
import {CartType} from "../../../Types/CartType";
import {ChannelContext} from "../../../Contexts/ChannelContext";
import {UserContext} from "../../../Contexts/UserContext";

function OldPlatformStyle(): React.ReactElement {
    const productsListContext = useContext(ProductsListContext);
    const [data, setData] = useState(productsListContext?.products);

    useEffect(() => {
        setData(productsListContext?.products);
    }, [productsListContext?.products]);

    return(
        <div className="old-platform">
            {productsListContext?.products.map((product, key) => {
                return <Item data={product} key={product.id} />
            })}
        </div>
    );
}

function Item({data}: {data: ProductInterface}): React.ReactElement {
    const cartContext               = useContext(CartContext);
    const productsListContext       = useContext(ProductsListContext);
    const channelContext            = useContext(ChannelContext);
    const userContext               = useContext(UserContext);
    const product                   = useProductData(data);
    const {t}                       = useTranslation();
    const [response, setResponse]   = useState<CartType|null>(null);
    const {visibility, toggleVisibleModal} = useSimpleModalState();

    // useEffect(() => {
    //     if (response !== null){
    //         cartContext?.setCart(response);
    //     }
    // }, [response]);

    if (product === null){
        return <></>;
    }

    const iconsDesignation = new IconsDesignation(product, t);

    function add2Cart(data: any): void {
        POST('/user/cart/add', {product_id: data.product_id, quantity: data.quantity})
            .then((response) => {
                if (response.status === 200){
                    const broadcastChannel = new BroadcastChannel('reload-cart');

                    broadcastChannel.postMessage('reload');

                    return  response.json();
                }
            });
    }

    function displayPrice(): React.ReactElement {
        if (!userContext?.user && channelContext?.displayPriceNotLogged === true){
            return <div><span>{t('products-list.disable-price-not-logged')}</span></div>
        }

        if (userContext?.user && channelContext?.displayPriceLogged === true){
            return <div><span>{t('products-list.disable-price-logged')}</span></div>
        }

        return (
            <>
                <div className="netto" >
                    <span>{t('products-list.price-netto-after-discount')}:</span> <span>{product?.priceNetto !== 0 ? product?.priceNetto.toFixed(2) : product?.priceBefore.toFixed(2)} </span>
                </div>
                <div className="brutto" >
                    <span>{t('products-list.price-brutto-after-discount')}:</span> <span>{product?.priceBrutto !== 0 ? product?.priceBrutto.toFixed(2) : product?.priceBeforeBrutto.toFixed(2)} </span>
                </div>
            </>
        );
    }

    function displayQuantity(): React.ReactElement {
        if (!userContext?.user && channelContext?.displayQuantityNotLogged === true){
            return <div><span>{t('products-list.disable-quantity-not-logged')}</span></div>
        }

        if (userContext?.user && channelContext?.displayQuantityLogged === true){
            return <div><span>{t('products-list.disable-quantity-logged')}</span></div>
        }

        return (
            <div className="quantity" >
                <span>{t('products-list.avail')}:</span>
                {/*@ts-ignore */}
                <span><QuantityDisposition quantity={product.quantity} unit={product.unit} /></span>
            </div>
        );
    }

    return (
        <section className="product" >
            {iconsDesignation.getIcons()}

            <div className={'left'} >
                <div className="thumb" style={{backgroundImage: 'url('+product.thumb+')'}} onClick={() => toggleVisibleModal()} >
                    <i className="fas fa-search-plus more" />
                </div>
            </div>

            {/*@ts-ignore */}
            <SimpleModal visibility={visibility} hidden={toggleVisibleModal} className={'product-thumb-modal'} >
                <img src={product.bigThumb} className={'thumb-img'} />
            </SimpleModal>

            <div className="desc" >
                <div className="attributes" >

                    {displayPrice()}

                    <div className="index" >
                        <span>{t('products-list.index-et')}:</span>
                        <span>{product.indexHurt}</span>
                    </div>
                    <div className="ean" >
                        <span>{t('products-list.ean')}:</span>
                        <span>{product.ean !== null ? product.ean : '-'}</span>
                    </div>

                    {displayQuantity()}

                    <div className="package" >
                        <span>{t('products-list.in-package')}:</span>
                        <span>{product.quantityPackage}</span>
                    </div>

                    {userContext?.user && channelContext?.displayPriceLogged === true ? null :
                        <div className="add-to-cart" >
                            <QuantityFields data={{product: product, products: productsListContext?.products, setProducts: productsListContext?.setProducts, setBody: add2Cart}} label={true} revers={true} />
                        </div>
                    }
                </div>
            </div>

            <Link to={'/product/'+product.slug} >
                <h2>{product.name}</h2>
            </Link>
        </section>
    );
}

export {OldPlatformStyle};