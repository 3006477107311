import React, {useContext, useEffect, useState} from "react";
import {CartContext} from "../Contexts/CartContext";
import {getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";
import {Table} from "./Table";
import {toLower} from "lodash";
import {useTranslation} from "react-i18next";
import {DELETE} from "../Hooks/RequestV2";
import {UserContext} from "../Contexts/UserContext";

function MiniCart(): React.ReactElement {
    const [visible, setVisible] = useState<string>('');
    const cartContext = useContext(CartContext);
    const userContext = useContext(UserContext);
    const {t} = useTranslation();
    const [pagination, setPagination] = useState({
        pageIndex: 0, //initial page index
        pageSize: 5, //default page size
    });

    useEffect(() => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        if (params.has('mini-cart') && cartContext?.cart?.products && cartContext?.cart?.products.length > 0 && userContext?.user && userContext?.user.admin){
            setVisible('visible');
        }
    }, [cartContext?.cart?.products]);

    function closeModal(){
        setVisible('hidden');

        const url = new URL(window.location.href);

        url.searchParams.delete('mini-cart');

        window.history.replaceState({}, '', url);
    }

    const columns = [
        {
            header: 'Nazwa produktu',
            accessorKey: 'translation.name'
        },
        {
            header: 'Producent',
            accessorKey: 'producer.name'
        },
        {
            header: 'Wartość',
            accessorKey: 'price',
            cell: ({getValue, row}: any) => {
                let totalBrutto, totalNetto;

                if (getValue().priceAfterDiscountNetto > 0){
                    totalNetto = (row.original.cartItemQuantity * row.original.quantityInPackage) * parseFloat(getValue().priceAfterDiscountNetto);
                    totalBrutto = (row.original.cartItemQuantity * row.original.quantityInPackage) * parseFloat(getValue().priceAfterDiscountBrutto);
                } else {
                    totalNetto = (row.original.cartItemQuantity * row.original.quantityInPackage) * parseFloat(getValue().priceBeforeDiscountNetto);
                    totalBrutto = (row.original.cartItemQuantity * row.original.quantityInPackage) * parseFloat(getValue().priceBeforeDiscountBrutto);
                }

                return (
                    <>
                        <div>{totalNetto.toFixed(2)} netto</div>
                        <div>{totalBrutto.toFixed(2)} brutto</div>
                    </>
                );
            }
        },
        {
            header: 'Ilość zamówiona',
            accessorKey: 'id',
            cell: ({row}: any) => {
                return  row.original.saleDepartmentSum + ' ' + t('products-list.'+toLower(row.original.unit))
            }
        }
    ];

    const table = useReactTable({
        data: cartContext?.cart?.products || [],
        columns,
        initialState: {
            pagination: {
                pageIndex: 0, //custom initial page index
                pageSize: 100, //custom default page size
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    function clearCart(){
        DELETE('/user/cart', {})
            .then((response) => {
                if (response.status === 200){
                    setVisible('hidden');

                    const url = new URL(window.location.href);

                    url.searchParams.delete('mini-cart');

                    window.history.replaceState({}, '', url);

                    window.location.reload();
                }
            })
    }

    function stayCart(){
        setVisible('hidden');

        const url = new URL(window.location.href);

        url.searchParams.delete('mini-cart');

        window.history.replaceState({}, '', url);
    }

    return (
        <div className={'simple-modal mini-cart '+visible} >
            <div className="wrapper" >
                <div className="simple-modal-box" >
                    <div className="wrapper" >
                        <button type={'button'} className="fas fa-times" onClick={() => closeModal()} ></button>

                        <div className={'scroll'} >
                            <h3>Handlowcu, w Twoim koszyku są produkty. Pamiętaj, że jako handlowiec masz tylko jeden koszyk tj. nie masz indywidualnych koszyków dla różnych kontrahentów. Jeżeli zostawisz w koszyku te produkty to upewnij się czy powinny one być zamówione dla kontrahenta w imieniu którego się logujesz.</h3>

                            <Table table={table} additionalBottom={undefined} />

                            <div className={'sum'} >
                                <div className={'left'} >
                                    Wartość całego koszyka:
                                </div>
                                <div className={'right'} >
                                    <div>{cartContext?.cart?.sum_netto} netto</div>
                                    <div>{cartContext?.cart?.sum_brutto} brutto</div>
                                </div>
                            </div>

                            <div className={'buttons'} >
                                <button className={'btn red'} type={'button'} onClick={() => clearCart()} ><i className={'fas fa-times'} /> Skasuj koszyk i kontynuuj</button>
                                <button className={'btn'} type={'button'} onClick={() => stayCart()} ><i className={'fas fa-check'} /> Zostaw koszyk i kontynuuj</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {MiniCart};