import React from "react";
import {FilterComponent} from "./FilterComponent";
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";

function DataPeriod(): React.ReactElement {
    const form = useFormContext();
    const {t} = useTranslation();

    const DateRanges = {
        0: t('user-page.invoice.filters.date-period-options.last-7-days'),
        1: t('user-page.invoice.filters.date-period-options.last-14-days'),
        2: t('user-page.invoice.filters.date-period-options.last-30-days'),
        3: t('user-page.invoice.filters.date-period-options.last-60-days'),
        4: t('user-page.invoice.filters.date-period-options.date-range'),
    };


    return (
        <FilterComponent title={t('user-page.invoice.filters.choice-period')} className={'date-period'} >
            <select defaultValue={0} {...form.register('date-period')} >
                {Object.entries(DateRanges).map(([key, value]) => <option key={key} value={key} >{value}</option>)}
            </select>
        </FilterComponent>
    );
}

export {DataPeriod};