import React from "react";
import {useFormContext} from "react-hook-form";
import {FilterComponent} from "./FilterComponent";
import {useTranslation} from "react-i18next";

function DocumentType(): React.ReactElement {
    const form = useFormContext();
    const {t} = useTranslation();

    const DocumentTypes = {
        0: t('user-page.invoice.filters.invoice-types.invoice-sale'),
        1: t('user-page.invoice.filters.invoice-types.correction-quantity'),
        2: t('user-page.invoice.filters.invoice-types.correction-value')
    };

    return (
        <FilterComponent title={t('user-page.invoice.filters.document-type')} >
            <select {...form.register('document-type')} >
                {Object.entries(DocumentTypes).map(([key, value]) => <option key={key} value={key} >{value}</option>)}
            </select>
        </FilterComponent>
    );
}

export {DocumentType};