import {CartType} from "../../Types/CartType";
import {isFreeShippingAbstract} from "./isFreeShippingAbstract";
import React, {ReactElement} from "react";
import {TFunction} from "react-i18next";
import {SimpleModal} from "../../Components/SimpleModal";

interface ModalStateInterface {
    visibility: boolean,
    setVisibility: React.Dispatch<any>,
    toggleVisibleModal: () => void
}

class SendOrderButton extends isFreeShippingAbstract{

    private readonly translate: TFunction;

    private readonly modalState: ModalStateInterface;

    private readonly disabled: boolean;

    private setDisabled: React.Dispatch<boolean>;

    public constructor(cart: CartType, t: TFunction<any>, modalState: ModalStateInterface, pickup: boolean, disabled: boolean, setDisabled: React.Dispatch<boolean>) {
        super(cart, pickup);

        this.translate  = t;
        this.modalState = modalState;
        this.disabled   = disabled;
        this.setDisabled= setDisabled;
    }

    private button(type: 'submit' | 'reset' | 'button' | undefined = undefined, sendOrder: any, toggleModal?: () => void): ReactElement{
        return (
            <button className="btn" type={type} onClick={() => {
                if (toggleModal) {
                    toggleModal();
                } else {
                    sendOrder();
                }
            }} disabled={this.disabled} >
                <i className="fas fa-cart-arrow-down" >&nbsp;</i> {this.translate('cart.send-order')}
            </button>
        );
    }

    public get(modal: any, sendOrder: any): ReactElement {

        if (this.isFree()){
            return this.button('submit', sendOrder);
        }

        return(
            <div className="send-order" >
                {this.button('button', null, this.modalState.toggleVisibleModal)}
                {modal}
            </div>
        );
    }

}

export {SendOrderButton};