interface mergedDataInterface{
    id: number,
    name: string,
    children: object[]
}

class MergeAlphabetical{
    private readonly data: object[];
    private propertyAlphabeticalMerge: string;
    private mergedData: mergedDataInterface[];

    constructor(data: object[], propertyAlphabeticalMerge: string) {
        this.mergedData = [];
        this.data = data;
        this.propertyAlphabeticalMerge = propertyAlphabeticalMerge;
    }

    private currentLetter(item: any): string{
        if (!item.hasOwnProperty(this.propertyAlphabeticalMerge)){
            throw new Error('Property '+this.propertyAlphabeticalMerge+' not found in object who merge to alphabetically filter data list.');
        }

        return item[this.propertyAlphabeticalMerge][0].toLowerCase();
    }

    private merge(currentLetter: any, item: object, key: number): void{
        if (this.mergedData.hasOwnProperty(currentLetter)){
            this.mergedData[currentLetter].children = [...this.mergedData[currentLetter].children, item];
        } else {
            this.mergedData[currentLetter] = {id: key, name: currentLetter, children: [item]};
        }
    }

    private run(): void{
        let currentLetter: any;

        Object.values(this.data).map((item, key)=>{
            currentLetter = this.currentLetter(item);

            this.merge(currentLetter, item, key);
        });
    }

    public getMergedData(): object[]{
        this.run();

        return this.mergedData;
    }
}

export {MergeAlphabetical}