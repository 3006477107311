import Breadcrumb from "./Breadcrumb";
import {useContext} from "react";
import {ChannelContext} from "../Contexts/ChannelContext";

function UserAccount({title, className, children, breadcrumb}){
    const {user} = useContext(ChannelContext);

    if (user === null){
        return (<></>);
    }

    return(
        <>
            <Breadcrumb breadcrumb={breadcrumb} />

            <div className={'user-content-box '+className} >
                <h1>{title}</h1>
                <div className="content" >
                    {children}
                </div>
            </div>
        </>
    );
}

export default UserAccount;
