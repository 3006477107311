import {useContext, useRef} from "react";
import {useTranslation} from "react-i18next";
import {ChannelContext} from "../../Contexts/ChannelContext";
import {Notification} from "./Notification";

function RedirectNotification(){
    const {t}               = useTranslation();
    const channel           = useContext(ChannelContext);
    const url               = new URLSearchParams(document.location.search);

    return <Notification
        text={t('layout.ui.messages.redirect-message', {old_name: url.get('redirect'), new_name: channel?.name})}
        removeParamUrl={'redirect'}
        enableNotification={url.get('redirect') !== null} />
}

export {RedirectNotification};