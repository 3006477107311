import {CartType} from "../../Types/CartType";
import {TFunction} from "react-i18next";
import React from "react";
import {isFreeShippingAbstract} from "./isFreeShippingAbstract";

interface PaymentShippingInfoInterface {
    getShippingInfo: () => React.ReactElement
}

class PaymentShippingInfo extends isFreeShippingAbstract implements PaymentShippingInfoInterface {

    private readonly priceShipping: number;
    private readonly translate: TFunction;

    private readonly isExportDepartment: boolean;

    public constructor(cart: CartType, t: TFunction<any>, pickup: boolean, isExportDepartment: boolean) {
        super(cart, pickup);

        this.priceShipping      = cart.price_shipping;
        this.translate          = t;
        this.isExportDepartment = isExportDepartment;
    }

    private paymentShipping(): React.ReactElement {
        return (
            <>
                <div className="attr" >
                    <span>{this.translate(this.isExportDepartment ? 'cart.not-meet-logistic' : 'cart.not-free-delivery')}</span>
                    <span>{this.freeShippingLeft} {this.translate('products-list.before-tax')}</span>
                </div>
                {this.isExportDepartment ? false :
                    <div className="attr" >
                        <span>{this.translate('cart.price-for-delivery')}</span>
                        <span>{this.priceShipping} {this.translate('products-list.before-tax')}</span>
                    </div>
                }
            </>
        );
    }

    private freeShipping(): React.ReactElement {
        return (
            <>
                <div className={'attr free-shipping'} >
                    <i className="fas fa-shipping-fast"></i> {this.translate(this.isExportDepartment ? 'cart.logistic-minimum-met' : 'cart.free-shipping')}
                </div>
            </>
        );
    }

    public getShippingInfo(): React.ReactElement {
        if (this.pickup){
            return <></>;
        }

        return this.isFree() ? this.freeShipping() : this.paymentShipping();
    }
}

export {PaymentShippingInfo, type PaymentShippingInfoInterface};