import {useRef} from "react";

interface NotificationInterface {
    text: string,
    removeParamUrl: string,
    enableNotification: boolean
}

function Notification({text, removeParamUrl, enableNotification}: NotificationInterface){
    const notificationRef   = useRef<any>(null);

    function removeNotification(){
        notificationRef.current.classList.add('disabled');

        const currentUrl = new URL(window.location.href);

        currentUrl.searchParams.delete(removeParamUrl);

        window.history.replaceState({}, document.title, currentUrl.toString());
    }

    if (enableNotification){
        return (
            <div className={'notification'} ref={notificationRef} >
                <div>
                    <button type={'button'} onClick={() => removeNotification()}>
                        <i className={'fas fa-times'} />
                    </button>

                    <p>{text}</p>
                </div>
            </div>
        );
    }

    return <></>;
}

export {Notification};