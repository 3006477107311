import React, {MouseEventHandler, useEffect, useRef, useState} from "react";
import {ChangeHandler, FieldValues, UseFormGetValues, UseFormRegister} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {UseFormSetValue} from "react-hook-form/dist/types/form";

interface AdvancedSearchPropsInterface {
    setVisible: React.Dispatch<boolean>,
    register: UseFormRegister<any>,
    getValues:  UseFormGetValues<FieldValues>,
    setValue: UseFormSetValue<any>
}

function AdvancedSearch({setVisible, register, getValues, setValue}: AdvancedSearchPropsInterface){
    const {t} = useTranslation();
    const maxLength = 1500;
    const [left, setLeft] = useState<number>(maxLength);
    const [twoColumnNotification, setTwoColumnNotification] = useState(false);
    const [leftColumnNotification, setLeftColumnNotification] = useState(false);
    const modalRef = useRef<any>();

    useEffect(() => {
        sumTextareasChars();

        document.addEventListener('mousedown', function (event){
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setVisible(false);
            }
        });
    }, []);

    function sumTextareasChars(): void {
        const indexesHurt       = getValues('indexes_hurt');
        const eans              = getValues('eans');
        const indexesProducer   = getValues('indexes_producer');

        const sum = indexesHurt.length + eans.length + indexesProducer.length;
        const left = maxLength - sum;

        setLeft(left);
    }

    function validate(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        let leftColumn = 0;
        let rightColumn = 0;
        let moreThanOneFieldRightColumn = 0;

        const leftFields = [
            'name',
            'ean',
            'index_hurt',
            'index_producer',
            'description'
        ];

        const rightFields = [
            'indexes_hurt',
            'eans',
            'indexes_producer'
        ];

        leftFields.map((field: string) => {
            if (!!getValues(field)){
                leftColumn = 1;
                moreThanOneFieldRightColumn++;
            }
        });

        rightFields.map((field: string) => {
            if (!!getValues(field)){
                rightColumn = 1;
            }
        });

        if ((leftColumn + rightColumn) === 2){
            setTwoColumnNotification(true);
            setLeftColumnNotification(false);
            event.preventDefault();
        } else if (moreThanOneFieldRightColumn > 1) {
            setLeftColumnNotification(true);
            setTwoColumnNotification(false);
            event.preventDefault();
        } else {
            setTwoColumnNotification(false);
            setLeftColumnNotification(false);
        }
    }

    function reset(): void {
        setTwoColumnNotification(false);
        setLeftColumnNotification(false);

        const fields = [
            'name',
            'ean',
            'index_hurt',
            'index_producer',
            'description',
            'indexes_hurt',
            'eans',
            'indexes_producer'
        ];

        fields.map((field: string) => {
            setValue(field, '');
        });
    }

    return (
        <div className={'advanced-search-modal'} ref={modalRef} >
            <div className={'wrapper'} >
                <button className={'exit'} type={'button'} onClick={() => setVisible(false)} ><i className={'fas fa-times'} /></button>

                <section>
                    <h3>{t('layout.header.advance-search.only-one-field-title')}</h3>

                    <div className={'field'} >
                        <label>{t('layout.header.advance-search.name')}:</label>
                        <input type={'text'} {...register('name')}/>
                    </div>
                    <div className={'field'} >
                        <label>{t('layout.header.advance-search.ean')}:</label>
                        <input type={'text'} {...register('ean')}/>
                    </div>
                    <div className={'field'} >
                        <label>{t('layout.header.advance-search.index')}:</label>
                        <input type={'text'} {...register('index_hurt')}/>
                    </div>
                    <div className={'field'} >
                        <label>{t('layout.header.advance-search.index-producer')}:</label>
                        <input type={'text'} {...register('index_producer')}/>
                    </div>
                    <div className={'field'} >
                        <label>{t('layout.header.advance-search.description')}:</label>
                        <input type={'text'} {...register('description')}/>
                    </div>

                    {leftColumnNotification ?

                        <div className={'two-columns-notification'} dangerouslySetInnerHTML={{__html: t('layout.header.advance-search.left-colum-notification')}} />

                        : null}

                </section>
                <section>
                    <h3>{t('layout.header.advance-search.many-data-field-title')}</h3>
                    <p>{t('layout.header.advance-search.many-data-field-tip')}</p>

                    <div className={'field'} >
                        <label>{t('layout.header.advance-search.indexes')}:</label>
                        <textarea
                            maxLength={left > 0 ? 1500 : getValues('indexes_hurt').length}
                            {...register('indexes_hurt', {onChange: (event: ChangeHandler) => sumTextareasChars()})} ></textarea>
                    </div>
                    <div className={'field'} >
                        <label>{t('layout.header.advance-search.eans')}:</label>
                        <textarea
                            maxLength={left > 0 ? 1500 : getValues('eans').length}
                            {...register('eans', {onChange: (event: ChangeHandler) => sumTextareasChars()})} ></textarea>
                    </div>
                    <div className={'field'} >
                        <p dangerouslySetInnerHTML={{__html: t('layout.header.advance-search.many-data-field-tip-producer')}} />
                        <label>{t('layout.header.advance-search.indexes_producers')}:</label>
                        <textarea
                            maxLength={left > 0 ? 1500 : getValues('indexes_producer').length}
                            {...register('indexes_producer', {onChange: (event: ChangeHandler) => sumTextareasChars()})} ></textarea>
                    </div>

                    <p>{t('layout.header.advance-search.sum-left', {maxLength: maxLength, left: left})}</p>
                </section>
            </div>

            {twoColumnNotification ?

                <div className={'two-columns-notification'} dangerouslySetInnerHTML={{__html: t('layout.header.advance-search.two-columns-notification')}} />

                : null}

            <div className={'buttons'} >
                <button className={'btn silver'} type={'reset'} onClick={() => reset()} >{t('layout.header.advance-search.reset-button')}</button>
                <button className={'btn'} onClick={validate} >{t('layout.header.advance-search.search-button')}</button>
            </div>
        </div>
    )
}

export {AdvancedSearch};