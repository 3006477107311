import {FieldValues, useFormContext, UseFormReturn, useWatch} from "react-hook-form";
import {useParams, useSearchParams} from "react-router-dom";
import {TFunction, useTranslation} from "react-i18next";
import React, {useEffect, useReducer, useState} from "react";
import {getRawData} from "../../../Hooks/FormDataHelper";
import {getSearchFilterFromURL} from "../FiltersDataHelper";

class TagsHelper{
    private _form:  UseFormReturn<FieldValues, any>;
    private _translate:  TFunction<"translation", undefined>;
    private _reloadList: any;
    private _checkedHeaderMenuFilter: string|undefined;

    constructor(
        form: UseFormReturn<FieldValues, any>,
        translate: TFunction<"translation", undefined>,
        reloadList: any,
        filter: string|undefined) {

        this._form                      = form;
        this._translate                 = translate;
        this._reloadList                = reloadList;
        this._checkedHeaderMenuFilter   = filter;
    }

    public tagsTranslation(): any{
        return {
            categories:     this._translate('layout.menu.categories'),
            producers:      this._translate('layout.menu.producers'),
            brands:         this._translate('layout.menu.brands'),
            licenses:       this._translate('layout.menu.licenses'),
            promotions:     this._translate('layout.menu.promotions'),
            sales:          this._translate('layout.filters.only-sales-tag'),
            news:           this._translate('layout.menu.news'),
            quantity:       this._translate('layout.filters.quantity'),
            price_min:      this._translate('layout.filters.price')+' '+this._translate('layout.filters.from'),
            price_max:      this._translate('layout.filters.price')+' '+this._translate('layout.filters.to'),
            search:         this._translate('layout.filters.search'),
            name:           this._translate('layout.filters.product-name'),
            ean:            this._translate('layout.filters.ean'),
            index_producer: this._translate('layout.filters.index_producer'),
            index_hurt:     this._translate('layout.filters.index_hurt')
        }
    }

   public getFilterNameFromDOMList(filterName: string, filterId: string): string|null{
       const filterParam: any = document.querySelector('input[name*="'+filterName+'"][value="'+filterId+'"]') as HTMLElement;

       if (filterParam === null){

           // const currentURL = new URL(window.location.href);
           // const filterParams = currentURL.searchParams.get(filterName)?.split(',');
           // let params: string[] = [];
           //
           // if (filterParams!.length > 1){
           //      filterParams!.map((value: string, key) => {
           //         if (value !== filterId){
           //             params.push(value);
           //         }
           //     });
           //
           //     currentURL.searchParams.set(filterName, params.join(','));
           // } else {
           //     currentURL.searchParams.delete(filterName);
           // }
           //
           // window.history.pushState({ path: currentURL.href }, '', currentURL.href);

           return null;
       }

       return filterParam.parentNode.querySelector('.name').innerText;
   }

   public buildTags(): object[]{
       const data = {...getRawData(this._form.watch(), this._checkedHeaderMenuFilter), ...getSearchFilterFromURL()};
       const specialKeys = [
           'search',
           'name',
           'ean',
           'index_producer',
           'index_hurt',
           'description',
           'indexes_hurt',
           'eans',
           'indexes_producer',
           'inDescription'
       ];

       let key: any, newDataObject: any = [];

       for (key in data){
           if (specialKeys.includes(key)){
                continue;
           }

           if (Array.isArray(data[key])){
               newDataObject.push({
                   name: key,
                   values: data[key].map((id: any)=>{
                       if (this.getFilterNameFromDOMList(key, id) === null){
                           return null;
                       }

                       return {name: this.getFilterNameFromDOMList(key, id), id: id};
                   })
               });
           } else if (data[key] === 'true'){
               newDataObject.push({name: key, values: this._translate('layout.yes') });
           } else if (specialKeys.includes(key)) {
               newDataObject.push({name: key, values: data[key] });
           } else {
               if (key !== 'page'){
                   let value = (parseFloat(data[key]).toFixed(2)).replace('.', ',');

                   if (key === 'quantity'){
                       value = data[key];
                   }

                   newDataObject.push({
                       name: key,
                       values: value
                   });
               }
           }
       }

       for (key in newDataObject){
           if (Array.isArray(newDataObject[key].values)){
               newDataObject[key].values = newDataObject[key].values.filter((el: any) => { return el != null; });
           }
       }

       return newDataObject;
   }

    public removeTag(filterName: any, id: any){
        // const data = getRawData(this._form.getValues(), this._checkedHeaderMenuFilter);
        const data = this._form.getValues();
        let key, key2;

        for (key in data){
            if (Array.isArray(data[key])){
                if (data[key].length < 2 && filterName === key){
                    data[key] = false;
                } else {
                    for (key2 in data[key]){
                        if (data[key][key2] === id && key === filterName){
                            delete data[key][key2];
                        }
                    }
                }
            } else {
                if (key === filterName){
                    data[key] = false;
                }
            }
        }

        for (key in data){
            if (Array.isArray(data[key])){
                data[key] = data[key].filter((el: any) => {
                    return el != null
                });
            }
        }

        if (filterName === 'producers' && data.brands !== false && data.brands.length > 0){
            const allBrandsForProducer = Array.from(document.querySelectorAll<HTMLInputElement>('input[name*="brands"][data-parent-id="'+id+'"]'));

            allBrandsForProducer.map((value, key) => {
                if (data['brands'] !== false && data['brands'].includes(value.value)){
                    if (data['brands'].length < 2){
                        data['brands'] = false;
                    } else {
                        let newBrands = [];

                        for (key2 in data['brands']){
                            if (data['brands'][key2] !== value.value){
                                newBrands.push(data['brands'][key2]);
                            }
                        }

                        data['brands'] = newBrands;
                    }
                }
            });
        }

        this._form.reset(data);

        // this._form.trigger();
        this._reloadList();
    }

    public DOMTags(filterName: any, value: any){
        if (Array.isArray(value)){
            return value.map((v, k)=>{
                return <button className="tag" key={k} onClick={() => this.removeTag(filterName, v.id)} >{v.name}</button>;
            });
        }

        return <button className="tag" onClick={() => this.removeTag(filterName, value.id)} >{value}</button>;
    }

}

function Tags({send}: {send: any}){
    const form                              = useFormContext();
    const watch                             = useWatch();
    const {filter}                          = useParams();
    const [searchParams, setSearchParams]   = useSearchParams();
    const {t}                               = useTranslation();
    const helper                            = new TagsHelper(form, t, send, filter);
    const [tagsData, setTagsData]           = useState<object[]>([]);

    let tagValue;

    useEffect(()=>{
        const builderTags = helper.buildTags();

        setTagsData(builderTags);
    }, [watch, searchParams]);

    if (tagsData.length === 0){
        return <></>;
    }

    return(
        <div className="tags" >
            <div className="title" >{t('layout.filters.choice-filters')}:</div>
            <div className="container" >
                {tagsData.map((value: any, key: number)=>{

                    if (value.name === 'quantity'){
                        tagValue = t('layout.filters.over')+' '+value.values;
                    } else {
                        tagValue = value.values;
                    }

                    if (value.values === '-1'){
                        return ;
                    }

                    return(
                        <div className="filter" key={key} >
                            <span className="filter-title" >{helper.tagsTranslation()[value.name]}:</span>
                            {helper.DOMTags(value.name, tagValue)}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export {Tags}