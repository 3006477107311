import React from "react";
import {FilterComponent} from "./FilterComponent";
import {useFormContext} from "react-hook-form";

interface ValueRangeInterface {
    title: string,
    priceType: string
}

function ValueRange({title, priceType}: ValueRangeInterface): React.ReactElement {
    const form = useFormContext();

    return (
        <FilterComponent title={title} className={'value-range'} >
            <input type={'text'} {...form.register('value-from-'+priceType)} />
            <input type={'text'} {...form.register('value-to-'+priceType)} />
        </FilterComponent>
    );
}

export {ValueRange};