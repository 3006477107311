import React, {ReactNode} from "react";

interface FilterComponentInterface {
    children: ReactNode,
    title: string,
    className?: string
}

function FilterComponent({children, title, className}: FilterComponentInterface): React.ReactElement {
    return (
        <div className={'filter'+(className ? ' '+className : '')} >
            <label className={'filter-title'} >{title}</label>
            <div className={'filter-body'} >{children}</div>
        </div>
    );
}
export {FilterComponent}