import {ReactElement} from "react";

interface ThrobberInterface{
    readonly visible: boolean
}

function Throbber({visible}: ThrobberInterface): ReactElement{
    return(
        <div className={'throbber'+(visible ? '' : ' hidden')} >
            <div className={'wrapper'}>
                <i className="fas fa-circle-notch fa-spin" />
            </div>
        </div>
    );
}

export {Throbber, type ThrobberInterface};


