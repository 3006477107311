import {useEffect} from "react";
import {useMatches, useNavigate} from "react-router-dom";

const urlInclude = ['cart', 'user'];

function useRedirectToLoginForm(): void
{
    const matches: any = useMatches();
    const navigate = useNavigate();

    useEffect(() => {

        urlInclude.map(word => {
            if (matches.some((match: any) => {

                if (!match){
                    return;
                }

                return  match.hasOwnProperty('pathname') &&
                        match.pathname.includes(word) &&
                        match.hasOwnProperty('data') &&
                        match.data &&
                        match.data.hasOwnProperty('code') &&
                        match.data.code === 401
            })){
                navigate('/login');
            }
        });

    }, [JSON.stringify(matches)]);
}

export {useRedirectToLoginForm};