import {useContext, useRef} from "react";
import {useTranslation} from "react-i18next";
import {ChannelContext} from "../../Contexts/ChannelContext";
import {Notification} from "./Notification";

function ChangeCurrencyReloadCart(){
    const {t}               = useTranslation();
    const url               = new URLSearchParams(document.location.search);

    return <Notification
        text={t('layout.ui.messages.reload-cat')}
        removeParamUrl={'reload-cart-currency'}
        enableNotification={url.get('reload-cart-currency') !== null} />
}

export {ChangeCurrencyReloadCart}