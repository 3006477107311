import {useLoaderData} from "react-router-dom";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {Table} from "../../../Components/Table";
import UserAccount from "../../../Components/UserAccount";
import {GetPaymentsForReceivers} from "../../../Requests/User";
import {useContext, useEffect, useState} from "react";
import {UserIsNotLogged} from "../../../Components/UserUIMessages";
import {priceBuilder} from "../../../Hooks/Price";

import "../../../Assets/Css/Pages/User/Payment/payment.css";
import {UserContext} from "../../../Contexts/UserContext";
import {getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";
import {Throbber} from "../../../Components/Throbber";
import {GET, POST} from "../../../Hooks/RequestV2";

/**
 * Base Component
 */
function Payment(){
    const loader = useLoaderData();
    const {user}                            = useContext(UserContext);
    const [payments, setPayments]           = useState(loader);
    // const {receiverPayments, setReceiver}   = GetPaymentsForReceivers();
    const [throbber, setThrobber]           = useState(false);
    const [receiverPayments, setReceiverPayments] = useState([]);

    // useEffect(() => {
    //     setReceiver({receiver_id: payments.receivers[0].id});
    // }, [payments]);

    function setReceiver(body){
        if (parseInt(body.receiver_id) === 0){

            GET('/user/payments')
                .then((response) => {
                    if (response.ok){
                        return response.json();
                    }
                })
                .then(json => setPayments(json));

            return;
        }

        setThrobber(true);
        POST('/user/payments/receiver', body)
            .then((result) => {
                if (result.ok){
                    setThrobber(false);
                    return result.json();
                }
            })
            .then(receivers => {
                // setReceiverPayments(receivers);
                setPayments((prev) => {
                    return {
                        payments: receivers.payments,
                        receivers: prev.receivers,
                        total: prev.total,
                        expiredTotal: prev.expiredTotal,
                        totalReceiver: receivers.total
                    };
                });
            });
    }

    if (user === null){
        return(<UserIsNotLogged />);
    }

    if (!payments){
        return(
            <></>
        );
    }

    if (payments.length === 0){
        payments.payments = [];
        payments.total = 0;
        payments.receivers = [];
        payments.expiredTotal = 0;
    }

    // if (receiverPayments !== null){
    //     if (receiverPayments.length === 0){
    //         payments.payments = [];
    //         payments.total = 0;
    //     } else {
    //         payments.payments = receiverPayments.payments;
    //         payments.total = receiverPayments.total;
    //     }
    // }

    return(
        <>
            <Throbber visible={throbber} />
            <DOM payments={payments} setReceiver={setReceiver} />
        </>
    );
}

/**
 * DOM tree
 *
 * @param payments
 * @param setReceiver
 * @param breadcrumb
 * @returns {JSX.Element}
 * @constructor
 */
function DOM({payments, setReceiver}){
    const {t} = useTranslation();
    const breadcrumb = [{name:t('user-page.payment.title'), path: null}];
    const [stateReceiver, setStateReceiver] = useState(0);

    const table = useReactTable({
        data: payments.payments,
        columns: tableColumns({t}),
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    function additionalBottom(){
        return(
            <div className="total" >
                <div>
                    {payments.hasOwnProperty('totalReceiver') ?
                        <div>
                            {Object.entries(payments.totalReceiver).map(([key, total]) => {
                                return <div>{t('user-page.payment.totalReceiver', {symbol: key !== '' ? key : '(?)'})}:  {priceBuilder(total, key)}</div>;
                            })}
                        </div>
                        : null}
                </div>

                <div>
                    {Object.entries(payments.total).map(([key, total]) => {
                        return <div key={key} >{t('user-page.payment.total')}: {priceBuilder(total, key)}</div>
                    })}

                    {Object.entries(payments.expiredTotal).map(([key, total]) => {
                       return(
                           <div className={'red'} >
                               {t('user-page.payment.expiredWithoutDays', {price: priceBuilder(total, key)})}
                           </div>

                       );
                    })}
                </div>
            </div>
        );
    }

    function setOption(e){
        setReceiver({receiver_id: e.target.value});
        setStateReceiver(e.target.value);
    }

    return(
        <>
            <UserAccount title={t('user-page.payment.title')} className="my-payments" breadcrumb={breadcrumb} >
                <label>{t('user-page.payment.receiver-filter')}</label>
                <select value={stateReceiver} onChange={setOption} >
                    <option value="0">-- {t('user-page.payment.all-receivers')}</option>
                    {payments.receivers.map((receiver) => {
                        return(
                            <option value={receiver.id} key={receiver.id} >{receiver.name}</option>
                        );
                    })}
                </select>
                <Table table={table} additionalBottom={additionalBottom()} />
            </UserAccount>
        </>
    );
}

/**
 * @returns {[{header: *, accessorKey: string},{header: *, cell: (function({getValue: *})), accessorKey: string},{header: *, cell: (function({getValue: *})), accessorKey: string},{header: *, cell: (function({getValue: *})), accessorKey: string}]}
 */
function tableColumns({t}){
    return [
        {
            header: t('user-page.payment.document-number'),
            accessorKey: 'invoice',
            cell: ({getValue, row}) => {
                return(
                    <span className={parseInt(row.original.expired) > 0 ? 'red' : null}>
                        {getValue()}
                    </span>
                );
            }
        },
        {
            header: t('user-page.payment.create-date'),
            accessorKey: 'createdAt',
            cell: ({getValue, row}) => {
                const dateTime = moment(getValue());

                return(
                    <span className={parseInt(row.original.expired) > 0 ? 'red' : null}>
                        {dateTime.format('YYYY-MM-DD')}
                    </span>
                );
            }
        },
        {
            header: t('user-page.payment.payment-date'),
            accessorKey: 'paymentDate',
            cell: ({getValue, row}) => {
                const dateTime = moment(getValue());

                return(
                    <span className={parseInt(row.original.expired) > 0 ? 'red' : null} >
                        {dateTime.format('YYYY-MM-DD')} {parseInt(row.original.expired) > 0 ? t('user-page.payment.expired', {day: row.original.expired})  : null}
                    </span>
                );
            }
        },
        {
            header: t('user-page.payment.to-pay'),
            accessorKey: 'toPayment',
            cell: ({getValue, row}) => {
                return(
                    <span className={parseInt(row.original.expired) > 0 ? 'red' : null}>
                        {priceBuilder(parseFloat(getValue()), row.original.currency)}
                    </span>
                );
            }
        },
        {
            header: t('user-page.payment.not-pay'),
            accessorKey: 'notPaid',
            cell: ({getValue, row}) => {
                return(
                    <span className={parseInt(row.original.expired) > 0 ? 'red' : null}>
                        {priceBuilder(parseFloat(getValue()), row.original.currency)}
                    </span>
                );
            }
        }
    ];
}

export default Payment;
