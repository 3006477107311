import Breadcrumb from "../../Components/Breadcrumb";
import {useAdd2Cart, removeCartItem, removeCartItems, saveCart} from "../../Requests/Cart";
import React, {useContext, useEffect, useRef, useState} from "react";
import "../../Assets/Css/Pages/ProductsList/products_list.css"
import "../../Assets/Css/Pages/Cart/cart.css"
import {useTranslation} from "react-i18next";
import {UserIsNotLogged} from "../../Components/UserUIMessages";
import {Link, useLoaderData, useLocation, useNavigate} from "react-router-dom";
import {UserContext} from "../../Contexts/UserContext";
import {CartContext} from "../../Contexts/CartContext";
import {DELETE, GET, POST} from "../../Hooks/RequestV2";
import {useSimpleModalState, SimpleModal} from "../../Components/SimpleModal";
import {ProductsListContext} from "../../Contexts/ProductsListContext";
import {useForceReload} from "../../Hooks/ForceReload";
import {Throbber} from "../../Components/Throbber";
import {ListItemHelper} from "../ProductsList/ListItemHelper";
import {useProductData} from "../ProductsList/ProductData";
import {QuantityFields} from "../ProductsList/Components/QuantityFields";
import {IconsDesignation} from "../ProductsList/IconsDesignation";
import {PaymentShippingInfo} from "./PaymentShippingInfo";
import {SendOrderButton} from "./SendOrderButton";
import {ChannelContext} from "../../Contexts/ChannelContext";
import {getCookie} from "../../Hooks/Cookies";
import {QuantityDisposition} from "../ProductsList/Components/QuantityDisposition";

function Cart(){
    const loader                                = useLoaderData();
    const {t}                                   = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const {user}                                = useContext(UserContext);
    const {setCart, cart}                       = useContext(CartContext);
    const {isExportDepartment}                  = useContext(ChannelContext);
    const channelContext                        = useContext(ChannelContext);
    const currencyCode                          = getCookie('currency-code');
    const [products, setProducts]               = useState(loader);
    const [productsList, setProductsList]       = useState(loader.products);
    const {response, setBody}                   = removeCartItems();
    const {removeItem, requestRemoveItem, removeItemStatus}   = removeCartItem();
    const {saveCartResponse, setSaveCartBody, httpStatusCode} = saveCart();

    const [receiver, setReceiver]               = useState(null);
    const [pickup, setPickup]                   = useState(false);
    const [desc, setDesc]                       = useState('');
    const [summaryInfo, setSummaryInfo]         = useState(null);
    const [acceptOrderNotification, setAcceptOrderNotification] = useState(false);
    const [disabledButtons, setDisabledButtons] = useState(false);
    const [transferCartNotification, setTransferCartNotification] = useState(null);

    const modalState = useSimpleModalState();
    const cartNameRef = useRef();

    useForceReload(cartRequest);

    useEffect(() => {
        if (products.receivers.length === 1){
            setReceiver(products.receivers[0].id);
        }

        const searchParams = new URLSearchParams(location.search);

        if (searchParams.has('saved-cart-transfer')) {
            const savedCartTransfer = searchParams.get('saved-cart-transfer');
            if (savedCartTransfer === 'true') {

                setTransferCartNotification(t('cart.success-transfer-cart'));

                searchParams.delete('saved-cart-transfer');
                navigate({
                    pathname: location.pathname,
                    search: searchParams.toString(),
                }, { replace: true });
            }
        }

        if (searchParams.has('failed-cart-transfer')) {
            const savedCartTransfer = searchParams.get('failed-cart-transfer');
            if (savedCartTransfer === 'true') {

                setTransferCartNotification(t('cart.failed-transfer-cart'));

                searchParams.delete('failed-cart-transfer');
                navigate({
                    pathname: location.pathname,
                    search: searchParams.toString(),
                }, { replace: true });
            }
        }


        if (searchParams.has('mixed-cart-transfer')) {
            const savedCartTransfer = searchParams.get('mixed-cart-transfer');
            if (savedCartTransfer === 'true') {

                setTransferCartNotification(t('cart.mixed-transfer-cart'));

                searchParams.delete('mixed-cart-transfer');
                navigate({
                    pathname: location.pathname,
                    search: searchParams.toString(),
                }, { replace: true });
            }
        }
    }, [loader]);

    useEffect(() => {
        if (products !== null){
            setSummaryInfo(products);
        }
    }, [products, response]);

    useEffect(() => {
        if (removeItemStatus === 200 || response !== null){
            cartRequest();
            // window.scrollTo(0, 0);
        }
    }, [removeItemStatus, response]);

    useEffect(() => {
        setSummaryInfo(cart);
    }, [cart]);

    function cartRequest(){
        GET('/user/cart')
            .then((response) => response.json())
            .then((json) => {
                setProducts(json);
                setProductsList(json.products);
                setSummaryInfo(json);

                const broadcastChannel = new BroadcastChannel('reload-cart');

                broadcastChannel.postMessage('reload');

                setCart(json);
            });
    }


    if (user === null || user === false){
        return (
            <>
                <UserIsNotLogged />
            </>
        );
    }

    if (summaryInfo === null){
        return <></>;
    }

    if (products.products.length === 0){
        return(
            <div className={'empty-cart'} >
                {acceptOrderNotification ?
                    <div className={'accept-order-notification'} >
                        <span>{t('cart.accept-order-notification')}</span> <i className={'fas fa-check'} />
                    </div>
                    : ''}
                <div className="icon" >
                    <i className={'fas fa-shopping-cart'} />
                </div>
                <div className="title" >{t('cart.empty-cart')}</div>
            </div>
        );
    }

    function sendOrder(){
        if (receiver === null){
            return;
        }

        const data = {
            personal_pickup: pickup,
            note: desc,
            receiver_id: receiver
        };

        setDisabledButtons(true);
        document.querySelector('main .throbber').className = 'throbber visible';

        POST('/user/order', data)
            .then((response) => {
                if (response.status === 201){
                    if (products !== null){
                        setSummaryInfo(products);
                    }

                    GET('/user/cart')
                        .then((response) => response.json())
                        .then((json) => {
                            setProducts(json);
                            setProductsList(json.products);
                            setCart(json);
                            setAcceptOrderNotification(true);
                            window.scrollTo(0, 0);
                        });
                }

                setDisabledButtons(false);
                document.querySelector('main .throbber').className = 'throbber hidden';
            });
    }

    function isContractor(cart){
        // if (products.is_receiver === false){

        if (cart.receivers.length === 1){
            return ;
        }

        return(
            <>
                <div className="select-receiver" >
                    <h3>{t('cart.check-receiver')}:</h3>

                    <select
                        name="select-receiver"
                        required={true}
                        defaultValue=""
                        onChange={(e) => {
                            setReceiver(e.target.value);
                            e.target.setCustomValidity('');
                        }}
                        onInvalid={(e) => e.target.setCustomValidity(t('cart.receiver-error'))} >

                        <option disabled={true} value="" >{t('cart.check-address')}</option>
                        {cart.receivers.map((receiver, key) => {
                            return(
                                <option value={receiver.id} key={key} >
                                    {receiver.name} - {receiver.address.street}, {receiver.address.postalCode} {receiver.address.city}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </>
        );
        // }
    }

    function deleteItem(itemId, el){
        // el.current.parentNode.removeChild(el.current);
        requestRemoveItem({product_id: itemId});

        // DELETE('/user/cart/product/remove', {product_id: itemId})
        //     .then((response) => {
        //         if (response.status === 200){
        //             // el.current.remove();
        //             GET('/user/cart')
        //                 .then((response) => response.json())
        //                 .then((json) => {
        //                     setCart(json);
        //                     setProducts(json);
        //                     setProductsList(json.products);
        //                 });
        //         }
        //     });
    }

    const breadcrumb = [{name: t('cart.cart'), path: null}];

    return(
        <>
            <div className="cart" >
                <Breadcrumb breadcrumb={breadcrumb} />

                {transferCartNotification !== null ?

                    <div className="form-success" >
                        <i className="fas fa-check" ></i>
                        <span>{transferCartNotification}</span>
                    </div>

                    : null}

                <div className="products-list" >
                    <div className="products" >
                        <ProductsListContext.Provider value={{products: productsList, setProducts: setProductsList}} >

                            <div className="tabular">
                                <div className="header" >
                                    <div className="thumb" >{t('products-list.thumb')}</div>
                                    <div className="name" >{t('products-list.name')}<br/>{t('products-list.index-et')} | {t('products-list.ean')}<br/>{t('products-list.producer')}</div>
                                    <div className="quantity" >{t('products-list.avail')}</div>


                                    <div className="before-discount" >{t('products-list.before-discount')}</div>
                                    <div className="discount" >{t('products-list.discount')}</div>
                                    <div className="price" >{t('products-list.price-after-discount')}</div>

                                    <div className={'total'} >
                                        {t('cart.value')}
                                    </div>

                                    <div className="package" >{t('products-list.method-packing')}</div>
                                    <div className="sale" >{t('products-list.quantity')}</div>
                                </div>
                                {productsList.map((product) => {
                                    return <Item data={product} key={product.id} remove={deleteItem} setSummaryInfo={setSummaryInfo} />
                                })}
                            </div>

                        </ProductsListContext.Provider>
                    </div>
                </div>
                <SuccessSavedCartNotification httpStatusCode={httpStatusCode} />


                {user && channelContext?.displayPriceLogged === true ? null :

                    <>
                        <div className="cart-options" >
                            <div className="clear-cart" >
                                <ClearCart removeAction={setBody} />
                            </div>
                            <div className="saved-cart" >
                                <input name="name" placeholder={t('cart.template-name')} ref={cartNameRef} />
                                <button className="btn teal" onClick={() => setSaveCartBody({cart_name: cartNameRef.current.value})} >
                                    <i className="fas fa-save" >&nbsp;</i> {t('cart.save-cart')}
                                </button>
                            </div>
                        </div>

                        <form method="post" onSubmit={(e) => { e.preventDefault(); sendOrder(); }} >
                            <div className="desc-sum" >
                                <div className="desc" >
                                    <h3>{t('cart.note')}</h3>
                                    <div>{t('cart.desc-char-amount')}: {desc.length}/300</div>
                                    <textarea name="desc" maxLength={300} onChange={(e) => setDesc(e.target.value)} ></textarea>
                                    <input type="checkbox" name="personal_pickup" onClick={(e) => setPickup(!pickup)}/> {t('cart.private-receive')}
                                    {isContractor(products)}
                                </div>
                                <div className="sum" >
                                    <div className="attr" >
                                        <span>{t('cart.order-together')}</span><span>{summaryInfo.sum_brutto} {t('products-list.after-tax')} {summaryInfo.sum_netto} {t('products-list.before-tax')}</span>
                                    </div>
                                    <div className="attr" >
                                        <span>{t('cart.item-together')}</span><span>{summaryInfo.amount_item}</span>
                                    </div>
                                    <div className="attr" >
                                        <span>{t('cart.balance-used')}</span><span>-{summaryInfo.balance} {t('products-list.before-tax')}</span>
                                    </div>
                                    <div className="attr" >
                                        <span>{t('cart.balance-with-order')}</span><span>-{summaryInfo.balance_cart} {t('products-list.before-tax')}</span>
                                    </div>

                                    {summaryInfo ? new PaymentShippingInfo(summaryInfo, t, pickup, isExportDepartment).getShippingInfo() : ''}
                                    {summaryInfo ? new SendOrderButton(summaryInfo, t, modalState, pickup, disabledButtons, setDisabledButtons).get(
                                        <SimpleModal visibility={modalState.visibility} hidden={modalState.toggleVisibleModal} className={'send-order-button-modal'} >

                                            {
                                                isExportDepartment ?
                                                    t('cart.send-order-modal-export', {freeShipping: summaryInfo.free_shipping, currencyName: currencyCode})
                                                    :
                                                    t('cart.send-order-modal', {freeShipping: summaryInfo.free_shipping, priceShipping: summaryInfo.price_shipping})
                                            }


                                            <div className={'buttons'}>
                                                <button type={'button'} className={'btn cancel'} onClick={modalState.toggleVisibleModal} >
                                                    <i className="fas fa-times"  /> {t('cart.send-order-modal-cancel')}
                                                </button>
                                                <button
                                                    className={'btn send'}
                                                    disabled={disabledButtons}
                                                    onClick={() => {
                                                        modalState.toggleVisibleModal();
                                                        sendOrder();
                                                    }} >
                                                    <i className="fas fa-check" /> {t('cart.send-order-modal-send')}
                                                </button>
                                            </div>
                                        </SimpleModal>
                                    , sendOrder) : ''}
                                </div>
                            </div>
                        </form>
                    </>
                }


            </div>
        </>
    );
}

function Item({data, remove, setSummaryInfo}){
    const listItemHelper        = new ListItemHelper();
    const {cart, setCart}       = useContext(CartContext);
    const {setUser, user}       = useContext(UserContext);
    const {products, setProducts} = useContext(ProductsListContext);
    const channelContext        = useContext(ChannelContext);
    const product               = useProductData(data);
    const {t}                   = useTranslation();
    const ref                   = useRef(null);
    const {visibility, toggleVisibleModal, setVisibility} = useSimpleModalState();

    const iconsDesignation      = new IconsDesignation(product, t);

    /** New Logic add to cart and calculated */
    const {response, setBody} = useAdd2Cart();

    // useEffect(() => {
    //     if (response !== null){
    //         setCart(response);
    //         if (setSummaryInfo) {
    //             setSummaryInfo(response);
    //         }
    //     }
    // }, [response]);

    function displayPrice() {
        if (!user && channelContext?.displayPriceNotLogged === true) {
            return t('products-list.disable-price-not-logged')
        }

        if (user && channelContext?.displayPriceLogged === true) {
            return t('products-list.disable-price-logged')
        }

        return (
            <>
                <div className="netto" >{product.priceNetto > 0 ? product.priceNetto.toFixed(2) : product.priceBefore.toFixed(2)} {t('products-list.before-tax')}</div>
                <div className="brutto" >{product.priceBrutto > 0 ? product.priceBrutto.toFixed(2) : product.priceBeforeBrutto.toFixed(2)} {t('products-list.after-tax')}</div>
            </>
        );
    }

    function displayQuantity() {
        if (!user && channelContext?.displayQuantityNotLogged === true){
            return t('products-list.disable-quantity-not-logged')
        }

        if (user && channelContext?.displayQuantityLogged === true){
            return t('products-list.disable-quantity-logged')
        }

        return <QuantityDisposition quantity={product.quantity} unit={product.unit} />;
    }

    return(
        <>
            <div className="product" ref={ref} >
                <div className={'thumb-container'} >
                    <div className="thumb" style={{backgroundImage: 'url('+product.thumb+')'}} onClick={() => toggleVisibleModal()} >
                        <i className="fas fa-search-plus more" />
                        {iconsDesignation.getIcons()}
                    </div>
                </div>

                <div className="name" >
                    <Link to={'/product/'+product.slug} >
                        <h2>{product.name}</h2>
                    </Link>
                    <div className="index" >{t('products-list.index-et')} {product.indexHurt} | {t('products-list.ean')} {product.ean}</div>
                    <div className="producer" >{t('products-list.producer')} {product.producerName}</div>
                </div>
                <div className="quantity" >{displayQuantity()}</div>

                <div className="before-discount" >
                    {(!user && channelContext?.displayPriceNotLogged === true) || (user && channelContext?.displayPriceLogged === true) ? null :
                        <>
                            <div className="netto" >{product.priceBefore.toFixed(2)} {t('products-list.before-tax')}</div>
                            <div className="brutto" >{product.priceBeforeBrutto.toFixed(2)} {t('products-list.after-tax')}</div>
                        </>
                    }
                </div>

                <div className="discount" >
                    {(!user && channelContext?.displayPriceNotLogged === true) || (user && channelContext?.displayPriceLogged === true) ? null :
                        <>
                            {parseInt(product.discount)}%
                        </>
                    }

                    {product.isNoDiscount ? <div className={'final-price'} >{t('products-list.final-price')}</div> : ''}
                </div>

                <div className="price" >
                    {displayPrice()}
                </div>

                <div className={'total'} >
                    {(!user && channelContext?.displayPriceNotLogged === true) || (user && channelContext?.displayPriceLogged === true) ? null :
                        <>
                            <div className="netto" >{product.totalNetto} {t('products-list.before-tax')}</div>
                            <div className="brutto" >{product.totalBrutto} {t('products-list.after-tax')}</div>
                        </>
                    }
                </div>

                <div className="package" >
                    {(!user && channelContext?.displayQuantityNotLogged === true) || (user && channelContext?.displayQuantityLogged === true) ? null :
                        <>{ product.quantityPackage}</>
                    }
                </div>
                <div className="sale" >

                    {user && channelContext?.displayPriceLogged === true ? null :
                        <div className="add-to-cart" >
                            <QuantityFields data={{product, products, setProducts, setBody}} label={false} />

                            {typeof remove === 'function' ?

                                <button className="btn red item-remove-button" onClick={() => remove(product.id, ref)}  >
                                    <i className="fas fa-times" ></i> {t('cart.remove')}
                                </button>

                                : null}
                        </div>
                    }

                </div>
                <SimpleModal visibility={visibility} hidden={toggleVisibleModal} className={'product-thumb-modal'} >
                    <img src={product.bigThumb} className={'thumb-img'} />
                </SimpleModal>
            </div>
        </>
    );
}

function ClearCart({removeAction}){
    const {t} = useTranslation();
    const {visibility, toggleVisibleModal, setVisibility} = useSimpleModalState();

    function clearCart(){
        toggleVisibleModal();
        setTimeout(() => {
            removeAction();
        }, 1000);
    }

    return(
        <>
            <button className="btn red" onClick={toggleVisibleModal} >
                <i className="fas fa-times" >&nbsp;</i> {t('cart.clear-cart')}
            </button>
            <SimpleModal visibility={visibility} hidden={toggleVisibleModal}  >
                <div className={'icon'} >
                    <i className={'fas fa-cart-arrow-down'}/>
                </div>
                <div className={'modal-title'} >{t('cart.modal-clear-cart-title')}</div>

                <div className={'modal-btns'}>
                    <button className={'btn green confirm'} onClick={(e) => clearCart()} >{t('cart.modal-clear-cart-yes')}</button>
                    <button className={'btn red cancel'} onClick={toggleVisibleModal} >{t('cart.modal-clear-cart-no')}</button>
                </div>
            </SimpleModal>
        </>
    );
}

function SuccessSavedCartNotification({httpStatusCode}){
    const {t} = useTranslation();

    if (httpStatusCode === 0){
        return(<></>);
    }

    if (httpStatusCode === 201){
        return(
            <div className="form-success" >
                <i className="fas fa-check" ></i>
                <span>{t('cart.saved-cart-success')}</span>
            </div>
        );
    }

    return(
        <div className="form-error" >
            <i className="fas fa-times" ></i>
            <span>{t('cart.saved-cart-empty-name')}</span>
        </div>
    );

}

export default Cart;
