import "../../Assets/Css/Pages/Home/home.css"
import {Banners} from "./BannersComponent";

/**
 * Home page
 */
function Home() {
    return (
        <Banners/>
    );
}

export default Home;
