import React from "react";
import {FilterComponent} from "./FilterComponent";
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";

function InvoiceNumberSearch({label}: any): React.ReactElement {
    const form = useFormContext();
    const {t} = useTranslation();

    return (
        <FilterComponent title={label} className={'invoice-number'} >
            <input type={'search'} {...form.register('invoice-number')} />
        </FilterComponent>
    );
}

export {InvoiceNumberSearch};