import {Get, Post} from "../Hooks/Request";

/**
 * Request for menu filters
 *
 * - Categories
 * - Producers
 * - Brands
 * - Licenses
 */
function MenuFilters(){
    const {response, setSend, httpStatusCode} = Get('/filters/menu');

    return {filters: response, getFilters: setSend, filtersHttpStatusCode: httpStatusCode};
}

/**
 * Request for filters WITH products inside
 */
function ProductsListFilters(){
    const {response, setBody, httpStatusCode} = Post('/filters/products-list');

    return {filterResponse: response, filterRequest: setBody, filtersStatusCode: httpStatusCode};
}

export {MenuFilters, ProductsListFilters};
