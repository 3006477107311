import React from "react";
import {ProductsListType} from "../Types/ProductsListType";
import {ProductInterface} from "../Pages/ProductsList/DataInterface";

interface ProductsListContextInterface{
    products: ProductInterface[],
    setProducts: React.Dispatch<ProductInterface[]|null>
}

const ProductsListContext = React.createContext<ProductsListContextInterface|null>(null);

export {ProductsListContext};