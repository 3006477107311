import React, {useState} from "react";

interface useStatesInterface{
    states: {
        enableTimeout: null|number,
        setEnableTimeout: React.Dispatch<null|number>,
        enableHover: boolean,
        setEnableHover: React.Dispatch<boolean>,
        menu: null|string,
        setMenu: React.Dispatch<null|string>,
        subMenu1: null|number,
        setSubMenu1: React.Dispatch<null|number>,
        subMenu2: null|number,
        setSubMenu2: React.Dispatch<null|number>,
        search: null|string,
        setSearch: React.Dispatch<null|string>,
        clickableFirstMenu: boolean,
        setClickableFirstMenu: React.Dispatch<boolean>,
        emptyResultsSearching: boolean,
        setEmptyResultsSearching: React.Dispatch<boolean>
    }
}

function useStates(): useStatesInterface{
    const [enableTimeout, setEnableTimeout]             = useState<null|number>(null);
    const [enableHover, setEnableHover]                 = useState<boolean>(false);
    const [menu, setMenu]                               = useState<null|string>(null);
    const [subMenu1, setSubMenu1]                       = useState<null|number>(null);
    const [subMenu2, setSubMenu2]                       = useState<null|number>(null);
    const [search, setSearch]                           = useState<null|string>(null);
    const [clickableFirstMenu, setClickableFirstMenu]   = useState<boolean>(true);
    const [emptyResultsSearching, setEmptyResultsSearching] = useState(false);

    return {
        states: {
            enableTimeout, setEnableTimeout,
            enableHover, setEnableHover,
            menu, setMenu,
            subMenu1, setSubMenu1,
            subMenu2, setSubMenu2,
            search, setSearch,
            clickableFirstMenu, setClickableFirstMenu,
            emptyResultsSearching, setEmptyResultsSearching
        }
    };
}

export {useStates}