import {CartType} from "../../Types/CartType";

abstract class isFreeShippingAbstract {

    protected readonly freeShippingLeft: number;

    protected readonly pickup: boolean;

    public constructor(cart: CartType, pickup: boolean) {
        this.freeShippingLeft = cart.free_shipping;
        this.pickup = pickup;
    }

    protected isFree(): boolean {
        return this.freeShippingLeft == 0 || this.pickup;
    }
}

export {isFreeShippingAbstract}