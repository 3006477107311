import React, {useEffect, useRef, useState} from "react";
import {RouterProvider} from "react-router-dom";
import {GET} from "./Hooks/RequestV2";
import {Routing} from "./Routing";

import {ChannelType} from "./Types/ChannelType";
import {ChannelContext} from "./Contexts/ChannelContext";
import "./Assets/Css/global.css";
import {SetContractorFromAdmin} from "./Components/SetContractorFromAdmin";
import {useTranslation} from "react-i18next";
import {i18n} from "i18next";
import {Channel} from "diagnostics_channel";
import {SetContractorFromUrl} from "./Components/SetContractorFromUrl";
// @ts-ignore
import logo from "./Assets/Images/logo.png";

/**
 * Main component
 *
 * - Base request for channel (sale department) - fond for Origin header
 * - Check if user is logged
 * - Channel and User context for all components
 */
function App(): any {
    const [channel, setChannel] = useState<ChannelType|null>(null);
    const [channelStatusCode, setChannelStatusCode] = useState<number|null>(null);
    const {i18n} = useTranslation();

    useEffect(() => {
        SetContractorFromAdmin();
        SetContractorFromUrl();

        GET('/channel').then((response) => {
            setChannelStatusCode(response.status);

            return response.json();
        }).then((json: ChannelType) => {
            setLanguage(i18n, json);
            setChannel(json);
        });
    }, []);


    if (channelStatusCode && channelStatusCode !== 200){
        return <ChannelNotFound httpStatusCode={channelStatusCode} />
    }

    if (channel && !channel.hostnameEnabled) {
        return deployInformation(channel.deployTime);
    }

    if (channel){

        return (
            <ChannelContext.Provider value={channel} >
                <RouterProvider router={Routing()} />
            </ChannelContext.Provider>
        );
    }

    return <></>;
}

/**
 * Channel not found or backend has exception ...
 */
function ChannelNotFound({httpStatusCode}: {httpStatusCode: number}){
    return(
        <div style={{textAlign: 'center', margin: '50px auto', fontWeight: '400', fontSize: '35px', maxWidth:'1000px'}} >
            Widzisz ten komunikat prawdopodobnie dlatego, że szukasz złego działu handlowego 😔
            - sprawdź czy w adresie strony nie występuje błąd. W innym wypadku skontaktuj się z nami w celu ustalenia problemu.
            <br/>
            <span style={{fontSize: '9px'}} >KOD BŁĘDU: {httpStatusCode}</span>
        </div>
    );
}

function deployInformation(deployTime: string) {
    return (
        <div
            style={{
                textAlign: "center",
                margin: "50px auto",
                fontWeight: "400",
                fontSize: "35px",
                maxWidth: "1000px",
                lineHeight: "1.5",
            }}
        >
            <img
                src={logo}
                alt="Logo EuroTrade"
                style={{ display: "block", margin: "0 auto", maxWidth: "100%", height: "auto" }}
            /><br />
            <div style={{ marginTop: "20px" }}>
                Drogi Użytkowniku,<br />
                obecnie prowadzimy prace serwisowe. Dołożymy wszelkich starań, aby Platforma ETconnect była dostępna w możliwie najkrótszym czasie.<br />
                Planowane zakończenie prac serwisowych: <strong>{deployTime}</strong>
                <br /><br />
                Dziękujemy za cierpliwość i zrozumienie.
                <br /><br /><br />
                Dear User,<br />
                We are currently performing maintenance work. We will make every effort to ensure that the ETconnect Platform is available as soon as possible.<br />
                Planned end of maintenance work: <strong>{deployTime}</strong>
                <br /><br />
                Thank you for your patience and understanding.

            </div>
        </div>
    );
}


function setLanguage(i18n: i18n, channel: ChannelType): void {
    const langOrder = ['pl_PL', 'en_GB'];
    let lang: string | null = null;
    let otherLangs: string[] = [];

    langOrder.map((defaultLang: string) => {
        channel.locales.map((locale: any) => {
            if (locale.code === defaultLang && lang === null){
                lang = (locale.code).replace('_', '-');
            }

            otherLangs.push(locale.code);
        });
    });

    if (!localStorage.getItem('i18nextLng')) {
        if (lang !== null){
            localStorage.setItem('i18nextLng', lang);
            i18n.changeLanguage(lang);
        }
    } else {
        const storedLang = localStorage.getItem('i18nextLng');

        if (storedLang && !otherLangs.includes(storedLang.replace('-', '_'))){
            if (lang){
                localStorage.setItem('i18nextLng', lang);
                i18n.changeLanguage(lang);
            }
        } else {
            if (storedLang){
                i18n.changeLanguage(storedLang);
            }
        }
    }
}

export default App;
