import React, {useRef, useState}    from "react";
import {useOnClickOutside}          from "usehooks-ts";

/**
 * Container of dropdown element
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Dropdown(props)
{
    const [activeList, setActiveList]   = useState('');
    const toggleRef                     = useRef(null);

    function toggleActiveList(){
        setActiveList(
            (activeList !== '' && activeList !== 'hidden' ? 'hidden' : 'active')
        );
    }

    function deactiveList(){
        setActiveList((activeList === '' ? '' : 'hidden'));
    }

    useOnClickOutside(toggleRef, deactiveList);

    return(
        <div className={'dropdown'+(props.className ? ' '+props.className : '')} >
            {props.children.map((child, i) =>
                React.cloneElement(child, {
                    key: i,
                    active: {activeList, toggleActiveList, toggleRef}})
            )}
        </div>
    );
}

/**
 * Dropdown active element or default value
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function DropdownAnchor(props)
{
    return(
        <div className="dropdown-anchor" ref={props.active.toggleRef} onClick={() => props.active.toggleActiveList()} >
            <button>
                {props.children}
                <i className={'fas fa-angle-right' + (props.active.activeList !== 'active'? '' : ' fa-rotate-90' )} ></i>
            </button>
        </div>
    );
}

/**
 * Container for elements list in dropdown
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function DropdownList(props)
{
    return(
        <div className={'dropdown-list-wrapper'} >
            <div className={'dropdown-list '+ props.active.activeList} >
                <div className="wrapper" >
                    {props.children}
                </div>
            </div>
        </div>
    );
}

/**
 * Dropdown element item
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function DropdownItem(props)
{
    return(
        <button onClick={props.clickEvent} >{props.children}</button>
    );
}

export {Dropdown, DropdownAnchor, DropdownList, DropdownItem};
