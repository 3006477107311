import React, {useContext, useEffect} from "react";
import "../../../../Assets/Css/Pages/User/Invoices/filters.css";
import {useFormContext} from "react-hook-form";
import {DataPeriod} from "./FormFields/DataPeriod";
import {DateRange} from "./FormFields/DateRange";
import {InvoiceNumberSearch} from "./FormFields/InvoiceNumberSearch";
import {Receiver} from "./FormFields/Receiver";
import {UserContext} from "../../../../Contexts/UserContext";
import {ValueRange} from "./FormFields/ValueRange";
import {DocumentType} from "./FormFields/DocumentType";
import {ProductEAN} from "./FormFields/ProductEAN";
import {ProductIndex} from "./FormFields/ProductIndex";
import {useTranslation} from "react-i18next";

function Filters({onSubmit}: any): React.ReactElement {
    const form = useFormContext();
    const {t} = useTranslation();
    const userContext = useContext(UserContext);

    return (
        <form onSubmit={form.handleSubmit(onSubmit)} >
            <div className={'top-left'} >
                <DataPeriod />
                {parseInt(form.watch('date-period')) === 4 ? <DateRange /> : null}
            </div>
            <div className={'top-right'} >
                <DocumentType />
                {userContext?.user && userContext?.user.receiver.isMain ? <Receiver /> : null}
            </div>
            <div className={'bottom'} >
                <ValueRange title={t('user-page.invoice.filters.value-netto')} priceType={'netto'} />
                <ValueRange title={t('user-page.invoice.filters.value-brutto')} priceType={'brutto'} />
                <ProductEAN />
                <ProductIndex />

                <button className={'btn'} >{t('user-page.invoice.filters.button')}</button>
            </div>
        </form>
    );
}

function Search({onSubmit}: any): React.ReactElement {
    const form = useFormContext();
    const {t} = useTranslation();

    return (
        <form onSubmit={form.handleSubmit(onSubmit)} >
            <div className={'top-left'} >
                <InvoiceNumberSearch label={t('user-page.invoice.filters.search-invoice-nr')} />
                <button className={'btn'}>{t('user-page.invoice.search-button')}</button>
            </div>
        </form>
    );
}

export {Filters, Search};