import {useContext} from "react";
import {ChannelContext} from "../../../Contexts/ChannelContext";
import {useTranslation} from "react-i18next";
import {UserContext} from "../../../Contexts/UserContext";

function QuantityDisposition({quantity, unit}: {quantity: number, unit: string}){
    const {t}                           = useTranslation();
    const channelContext                = useContext(ChannelContext);
    const userContext                   = useContext(UserContext);
    const isPrecision: boolean          = channelContext?.productQuantityPrecision === 'precision';
    const notPrecisionNumber: number    = channelContext?.notPrecisionNumber ?? 0;

    if (!channelContext){
        return <></>;
    }

    if ((isPrecision || quantity <= notPrecisionNumber) || (userContext?.user && userContext?.user?.admin)){
        return (
            <>{quantity+' '+unit.toLowerCase()}</>
        );
    }

    return (
        <>
            {t('products-list.quantity-more-than', {notPrecisionNumber: notPrecisionNumber, unit: unit})}
        </>
    );
}

export {QuantityDisposition};