import React from "react";
import {UserType} from "../Types/UserType";

interface UserContextInterface{
    user: UserType|null|false,
    setUser: React.Dispatch<UserType>|null
}

const UserContext = React.createContext<UserContextInterface|null>(null);

export {UserContext, type UserContextInterface};
