import UserAccount from "../../../Components/UserAccount";
import {useTranslation} from "react-i18next";
import "../../../Assets/Css/Pages/User/ImportOrder/import-order.css";
import React, {useContext, useRef, useState} from "react";
import RequestConfig from "../../../Requests/RequestConfig.json";
import {useCookie} from "../../../Hooks/Cookies";
import {CartContext, CartContextInterface} from "../../../Contexts/CartContext";
import {GET} from "../../../Hooks/RequestV2";
import {Throbber} from "../../../Components/Throbber";
import {SimpleModal, useSimpleModalState} from "../../../Components/SimpleModal";
import {UserContext, UserContextInterface} from "../../../Contexts/UserContext";
import {UserIsNotLogged} from "../../../Components/UserUIMessages";

interface ImportOrderResponse {
    fileType: 'ean'|'hurt_id'|'no file type'|null,
    badNumbers: number[],
    goodNumbersAmount: number,
    badNumbersAmount: number,
    badQuantityAmount: number,
    fullPackagingNumbers: number[]
}

const defaultResponseData: ImportOrderResponse = {
    fileType: null,
    badNumbers: [],
    goodNumbersAmount: 0,
    badNumbersAmount: 0,
    badQuantityAmount: 0,
    fullPackagingNumbers: []
}

const templatesFiles: any = {
    pl: {
        ean: 'Wgraj po kodzie EAN.xlsx',
        index: 'Wgraj po Indeksie ET.xlsx'
    },
    en: {
        ean: 'Upload by EAN.xlsx',
        index: 'Upload by ET Index.xlsx'
    }
};

function ImportOrder(){
    const {t, i18n} = useTranslation();
    const currentLangCode = i18n.language.split('-')[0];
    const inputFileRef = useRef<any>(null);
    const {getCookie} = useCookie();
    const cartContext = useContext<CartContextInterface|null>(CartContext);
    const [throbberVisible, setThrobberVisible] = useState(false);
    const [importResponse, setImportResponse] = useState<ImportOrderResponse>(defaultResponseData);
    const modalState = useSimpleModalState();
    const userContext = useContext<UserContextInterface|null>(UserContext);

    if (userContext === null || userContext?.user === false){
        return(<UserIsNotLogged />);
    }

    const breadcrumb = [{name: t('user-page.load-order.title'), path: null}];

    function load(){
        const file = inputFileRef.current?.files[0];

        const   formData = new FormData();
                formData.append('file', file);

                setThrobberVisible(true);

        fetch(RequestConfig.domain+'/user/upload-file', {
            credentials: 'include',
            method: 'POST',
            body: formData,
            headers: {
                'Content-Language': (i18n.language || 'pl-PL'),
                'Currency': (getCookie('currency-code') || 'pln')
            }
        }).then((response) => {
            if (response.status === 200){
                GET('/user/cart')
                    .then((responseCart) => {
                        return responseCart.json();
                    })
                    .then((json) => {
                        cartContext?.setCart(json);
                        setThrobberVisible(false);
                        inputFileRef.current.value = null;
                    });

                return response.json();
            }
        }).then((json: ImportOrderResponse) => setImportResponse(json));
    }


    return(
        <UserAccount title={t('user-page.load-order.title')} className="import-order" breadcrumb={breadcrumb} >
            <Throbber visible={throbberVisible} />
            <form onSubmit={(event) => {
                if (cartContext?.cart?.products.length !== 0){
                    modalState.toggleVisibleModal();
                } else {
                    load();
                }

                return event.preventDefault();
            }} >

                <div className={'field'} >
                    <label>{t('user-page.load-order.label-input')}</label>
                    <input type={'file'} name={'file'} ref={inputFileRef} required={true}
                           onChange={(event) => {
                               event.currentTarget.setCustomValidity("");
                           }}
                           onInvalid={(event) => {
                               event.currentTarget.setCustomValidity(t('user-page.load-order.input-error-message'));
                           }} />
                </div>
                <button className={'btn'} >{t('user-page.load-order.label-button')}</button>
            </form>

            <Logs importResponse={importResponse} />

            {/* @ts-ignore */}
            <SimpleModal visibility={modalState.visibility} hidden={modalState.toggleVisibleModal} className={'send-order-button-modal'} >
                <>
                    {t('user-page.load-order.modal-text')}
                    <div className={'buttons'}>
                        <button type={'button'} className={'btn cancel'} onClick={modalState.toggleVisibleModal} >
                            <i className="fas fa-times"  /> {t('user-page.load-order.modal-cancel-button')}
                        </button>
                        <button className={'btn send'} onClick={() => {
                            modalState.toggleVisibleModal();
                            load();
                        }} >
                            <i className="fas fa-check" /> {t('user-page.load-order.modal-confirm-button')}
                        </button>
                    </div>
                </>
            </SimpleModal>

            <div className={'examples'} >
                <div className={'template'}>
                    <a href={'/'+templatesFiles[currentLangCode].ean} >{t('user-page.load-order.download-a')}</a>
                </div>
                <div className={'template'} >
                    <a href={'/'+templatesFiles[currentLangCode].index} >{t('user-page.load-order.download-b')}</a>
                </div>

                <p dangerouslySetInnerHTML={{__html: t('user-page.load-order.not-empty-cart')}} />
            </div>
        </UserAccount>
    );
}

function Logs({importResponse}: {importResponse: ImportOrderResponse}){
    const {t} = useTranslation();

    const minimumOneProductAdd  = t('user-page.load-order.ok-response');
    const noOnceAdd             = t('user-page.load-order.error-response');

    const quantityOfAddedProducts       = t('user-page.load-order.good-numbers-amount', {goodNumbersAmount: importResponse.goodNumbersAmount});
    const emptyQuantityAmount           = t('user-page.load-order.empty-quantity-amount', {emptyQuantityAmount: importResponse.badQuantityAmount});
    const quantityOfNotFindableProducts = t('user-page.load-order.not-findable-products-quantity', {notFindableProductsQuantity: importResponse.badNumbersAmount});
    const fullPackaging                 = t('user-page.load-order.full-packaging-list');
    const notFindableProducts           = t('user-page.load-order.not-added-products-list');

    if (importResponse.fileType !== null){
        if (importResponse.fileType === 'no file type'){
            return (
                <div className={'logs'} >
                    <div className={'error'} >Zaczytany plik ma niewłaściwą strukturę. Ściągnij przykładowy plik z właściwą strukturą (link poniżej), wyedytuj go a następnie zaczytaj.</div>
                </div>
            );
        }

        return(
            <div className={'logs'} >
                {importResponse.goodNumbersAmount > 0 ? <div className={'ok'} >{minimumOneProductAdd}</div> : <div className={'error'} >{noOnceAdd}</div>}

                {importResponse.goodNumbersAmount > 0 ? <div className={'ok'} >{quantityOfAddedProducts}</div> : ''}

                {importResponse.badQuantityAmount > 0 ? <div className={'error'} >{emptyQuantityAmount}</div> : ''}

                {importResponse.badNumbersAmount > 0 ? <div className={'error'} >{quantityOfNotFindableProducts}</div> : ''}

                {importResponse.fullPackagingNumbers.length > 0 ?

                    <div className={'ok'} >
                        <div className={'title'} >{fullPackaging}</div>

                        <p>
                            {importResponse.fullPackagingNumbers.map((id: number) => {
                                return id+', ';
                            })}
                        </p>

                    </div>

                    : ''}

                {importResponse.badNumbers.length > 0 ?

                    <div className={'error'} >
                        <div className={'title'} >{notFindableProducts}</div>

                        <p>
                            {importResponse.badNumbers.map((id: number) => {
                                return id+', ';
                            })}
                        </p>

                    </div>

                    : ''}
            </div>
        );
    }


    return <></>;
}

export {ImportOrder};