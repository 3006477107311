import React, {useEffect, useLayoutEffect, useRef} from "react";
import {ScrollRestoration, useLoaderData, useNavigate, useParams} from "react-router-dom";
import {CategoriesFilter} from "./CategoriesFilter";
import {AlphabeticalFilter} from "./AlphabeticalFilter";
import Breadcrumb from "../../Components/Breadcrumb";
import {useTranslation} from "react-i18next";
import "../../Assets/Css/Pages/ProductFilters/index.css";

interface DefaultFilterItem {
    id: number,
    name: string,
    productsQuantity: number
}

interface CategoriesFilterItem {
    id: number,
    hurt_name: string,
    parent_id: number|null,
    products_quantity: number,
    tree_lvl: number,
    children: CategoriesFilterItem[]|null
}

interface Filters {
    categories: CategoriesFilterItem[],
    producers: DefaultFilterItem[],
    brands: DefaultFilterItem[],
    licenses: DefaultFilterItem[]
}

interface FiltersFacade {
    filters: Filters
}

function Index(): React.ReactElement {
    const loader: FiltersFacade   = useLoaderData() as FiltersFacade;
    const {menuitem}        = useParams();
    const navigate          = useNavigate();
    const {t}               = useTranslation();
    const listRef           = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [loader]);

    if (typeof menuitem !== 'string' || !loader.filters.hasOwnProperty(menuitem ?? '')){
        navigate('/');

        return <></>;
    }

    if (!loader){
        return <></>;
    }

    const breadcrumb = [{name: 'Filtr', path: null}, {name: t('layout.menu.'+menuitem), path: null}];

    return (
        <div className={'filter-page'}>
            <Breadcrumb breadcrumb={breadcrumb} />

            <ScrollRestoration />

            <div className={'list'} ref={listRef} >
                <UpToTop topRef={listRef} />
                {menuitem === 'categories' ?
                    <CategoriesFilter data={loader.filters.categories} /> :
                    <AlphabeticalFilter data={loader.filters[menuitem as keyof Omit<Filters, 'categories'>]} />}
            </div>
        </div>
    );
}

function UpToTop({topRef}: {topRef: React.RefObject<HTMLDivElement|null>}): React.ReactElement {
    return (
        <button className={'btn to-top'} onClick={() => topRef.current?.scrollIntoView()} >
            <i className="fas fa-arrow-circle-up" />
        </button>
    );
}

export {Index as ProductFilters, type CategoriesFilterItem, type DefaultFilterItem};