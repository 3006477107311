const types = [
    {id: '1', name: 'GOTÓWKA'},
    {id: '2', name: 'CZEK'},
    {id: '3', name: 'PRZELEW'},
    {id: '6', name: 'KOMPENSATA'},
    {id: '7', name: 'ZAL.POCZT'},
    {id: '8', name: 'FAKTORING'},
    {id: '9', name: 'KARTA KRE'},
    {id: 'C', name: 'ZA POBR'},
    {id: 'D', name: 'PRZEDPŁATA'},
    {id: 'E', name: 'SPEDYTOR'},
    {id: 'F', name: 'UBEZPIECZ'},
];

function getInvoicePaymentType(type){
    let t;
    for(let x = 0; x < types.length; x++){
        if (type === types[x].id){
            t = types[x].name;
        }
    }

    return t;
}

export default getInvoicePaymentType;
