import React from "react";

class PaginationHelper{

    private _count: number;
    private _perPage: number;
    private _activePage: number;
    private _pagesLimitInFrontAndBack: number;
    private _setActivePage: React.Dispatch<number>;
    private _send: any;

    constructor(
        count: number,
        perPage: number,
        activePage: number,
        pagesLimitInFrontAndBack: number,
        setActivePage: React.Dispatch<number>,
        send: any) {

        this._count                     = count;
        this._perPage                   = perPage;
        this._activePage                = activePage;
        this._pagesLimitInFrontAndBack  = pagesLimitInFrontAndBack;
        this._setActivePage             = setActivePage;
        this._send                      = send;
    }

    private getNumberOfPages(){
        return Math.ceil(this._count / this._perPage);
    }

    private getStartLoop(){
        const start = this._activePage - this._pagesLimitInFrontAndBack;

        if (start <= 1){
            return 1;
        }

        return start;
    }

    private getEndLoop(){
        const end = this._activePage + this._pagesLimitInFrontAndBack;

        if (end >= this.getNumberOfPages()){
            return this.getNumberOfPages();
        }

        return end;
    }

    public getPages(){
        let pages = [];

        if (this.getNumberOfPages() < 2){
            return null;
        }

        if (this.getStartLoop() > 1){
            pages.push(<button onClick={() => this.changePage(1)} key={1} >1 ....</button>);
        }

        for (let x = this.getStartLoop(); x <= this.getEndLoop(); x++){
            if (this._activePage === x){
                pages.push(<button key={x} disabled={true} className="active" >{x}</button>);
            } else {
                pages.push(<button key={x} onClick={() => this.changePage(x)} >{x}</button>);
            }
        }

        if (this.getEndLoop() < this.getNumberOfPages()){
            pages.push(<button onClick={() => this.changePage(this.getNumberOfPages())} key={this.getNumberOfPages()} >.... {this.getNumberOfPages()}</button>);
        }

        return pages;
    }

    public getBackButton(){
        if (this._activePage > 1){
            return <button onClick={() => this.changePage(this._activePage-1)} key={'prev'} ><i className="fas fa-angle-left" ></i></button>;
        }

        return null;
    }

    public getForwardButton(){
        if (this._activePage !== this.getNumberOfPages()){
            return <button onClick={() => this.changePage(this._activePage+1)} key={'next'} ><i className="fas fa-angle-right" ></i></button>
        }

        return null;
    }

    private changePage(page: any){
        const searchParams = new URLSearchParams(window.location.search);

        window.scrollTo(0, 0);

        searchParams.set('page', page);

        this._setActivePage(page);

        const url = window.location.origin+window.location.pathname+'?'+searchParams.toString();
        window.history.pushState({path: url}, '', url);
        this._send();
    }
}

export {PaginationHelper}