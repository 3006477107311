import {useLoaderData, useParams} from "react-router-dom";
import getInvoicePaymentType from "../../../Hooks/InvoicePaymentType";
import UserAccount from "../../../Components/UserAccount";
import {useContext, useState} from "react";
import "../../../Assets/Css/Pages/User/Invoice/invoice.css";
import {useTranslation} from "react-i18next";
import {UserIsNotLogged} from "../../../Components/UserUIMessages";
import {UserContext} from "../../../Contexts/UserContext";

function WZ(){
    const loader = useLoaderData();
    const [wz, setWz] = useState(loader);
    const {id}              = useParams();
    const {t}               = useTranslation();
    const {user} = useContext(UserContext);

    if (user === null){
        return (
            <UserIsNotLogged />
        );
    }

    if (wz === null){
        return(
            <></>
        );
    }

    const paymentType = getInvoicePaymentType(wz.paymentType);
    const breadcrumb = [{name: t('user-page.wzs.title'), path: '/user/wz'}, {name: wz.wzNumber, path: null}];

    return(
        <UserAccount title={t('user-page.wz.wz')+' '+wz.wzNumber} className="my-wz" breadcrumb={breadcrumb} >
            <div className="types" >
                <div className="type" >
                    <span>{t('user-page.wz.return')}</span>
                    <span>{(wz.isReturn !== null ? t('user-page.wz.yes') : t('user-page.wz.no'))}</span>
                </div>
                <div className="type" >
                    <span>{t('user-page.wz.forwarder')}</span>
                    <span>{wz.forwarder}</span>
                </div>
                <div className="type" >
                    <span>{t('user-page.wz.payment-type')}</span>
                    <span>{paymentType}</span>
                </div>
                <div className="type" >
                    <span>{t('user-page.wz.note-number')}</span>
                    <span>{wz.listNumber}</span>
                </div>
            </div>

            <table className="table-data" >
                <thead>
                <tr>
                    <th>{t('user-page.wz.item-name')}</th>
                    <th>{t('user-page.wz.item-price-netto')}</th>
                    <th>{t('user-page.wz.item-discount')}</th>
                    <th>{t('user-page.wz.item-price-after-discount-netto')}</th>
                    <th>{t('user-page.wz.item-quantity')}</th>
                </tr>
                </thead>
                <tbody>
                {wz.wzItem.map((item, key) => {
                    return(
                        <tr key={key} >
                            <td>{item.productName}</td>
                            <td>{item.netPrice.toFixed(2)}</td>
                            <td>{item.discount} %</td>
                            <td>{item.priceAfterDiscount.toFixed(2)}</td>
                            <td>{item.quantity}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </UserAccount>
    );
}

export default WZ;
