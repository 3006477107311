import React from "react";
import {useTranslation} from "react-i18next";
import {fileTypes, fileTypeData} from "./DownloadFile";

function ListOptions({table, rowQuantity, setFileFormat, fileFormat, setPayerCode, filterRequest}: any): React.ReactElement{
    const {t} = useTranslation();
    const positions = [10, 30, 50, 100, t('user-page.invoice.filters.all')];

    function changeRowOnPage(e: React.ChangeEvent<HTMLSelectElement>){
        if ([10, 30, 50, 100].includes(Number(e.target.value))){
            table.setPageSize(Number(e.target.value));
        } else {
            table.setPageSize(rowQuantity);
        }
    }

    function changeFormat(e: React.ChangeEvent<HTMLSelectElement>){
        setFileFormat(e.target.value);
    }

    return (
        <div className={'list-options'} >
            <div className={'row-quantity'} >
                <span>{t('user-page.invoice.filters.show')}</span>
                <select onChange={changeRowOnPage} >
                    {positions.map((option: number|string, key: number) => {
                        return <option value={option} key={key} >{option}</option>
                    })}
                </select>
                <span>{t('user-page.invoice.filters.positions')}</span>
            </div>
            <div className={'export'} >
                <span>{t('user-page.invoice.filters.file-type-select')}:</span>
                <select onChange={changeFormat} >
                    <option value={0} >-- Wybierz format</option>
                    {Object.values(fileTypes).map((file: fileTypeData , key: number) => {
                        if (file.format === 'xls' || file.format === 'pdf'){
                            return null;
                        }

                        return <option key={key} value={file.format} >{file.label}</option>;
                    })}
                </select>

                {fileFormat === fileTypes.EDIXML.format ?

                    <>
                        <span>{t('user-page.invoice.filters.payment-code')}:</span>
                        <input type={'text'} required={true} name={'payer-code'} onChange={(e) => setPayerCode(e.target.value)} />
                    </>

                    : null}
            </div>
            <div className={'info'} >
                {t('user-page.invoice.filters.info')}
            </div>
        </div>
    );
}

export {ListOptions};