import React, {useContext, useEffect, useRef, useState} from "react";
import logo     from "../../Assets/Images/logo.png";
import cartIcon     from "../../Assets/Images/cart.png"
import {useTranslation} from "react-i18next";
import {CartItemAmount} from "../../Requests/Cart";
import {ChannelContext} from "../../Contexts/ChannelContext";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {UserContext} from "../../Contexts/UserContext";
import {CartContext} from "../../Contexts/CartContext";
import {getSearchFilterFromURL} from "../../Pages/ProductsList/FiltersDataHelper";
import {Tooltip} from "react-tooltip";
import {AdvancedSearch} from "./Components/AdvancedSearch";

/**
 * Middle place of header with logo, search and cart button/number of item in cart
 *
 * @returns {JSX.Element}
 * @constructor
 */
function HeaderMiddle()
{
    const {user} = useContext(UserContext);

    return(
        <div className="middle" >
            <div className="logo" >
                <Link to="/" >
                    <img src={logo} alt="Logo EuroTrade" />
                </Link>
            </div>
            <div className="search" >
                <Search small={false} />
            </div>
            <div className="cart" >
                {user !== false ? <Cart /> : ''}
            </div>
        </div>
    );
}

const defaultSearchFormData = {
    search: '',
    name: '',
    ean: '',
    index_hurt: '',
    index_producer: ''
}

function Search({small}){
    const userContext = useContext(UserContext);
    const [userContextInit, setUserContextInit] = useState(false);
    const [search, setSearch] = useSearchParams();
    const {t} = useTranslation();
    const [advanceVisible, setAdvanceVisible] = useState(false);
    const {register, handleSubmit, reset, formState: { errors }, getValues, setValue}   = useForm(getSearchFilterFromURL());
    const formRef = useRef();
    const baseSearchFieldName = [
        'search',
        'inDescription'
    ];

    const advancedSearchFieldName = [
        'name',
        'ean',
        'index_hurt',
        'index_producer',
        'description',
        'indexes_hurt',
        'eans',
        'indexes_producer'
    ];

    let navigate = useNavigate();

    useEffect(() => {
        loadDataFromUrl();
    }, []);

    useEffect(() => {
        if (Object.keys(getSearchFilterFromURL()).length === 0){
            reset(defaultSearchFormData);
        } else {
            reset(getSearchFilterFromURL());
        }
    }, [search]);

    useEffect(() => {
        if (useContext === null){
            return;
        }

        if (!userContextInit){
            setUserContextInit(true);
            return;
        }

        setValue('inDescription', false);
        submitAllParameters(getValues());

    }, [userContext.user]);

    function toggleAdvanceVisible(){
        setAdvanceVisible(!advanceVisible);
    }

    /**
     * Get search form data, add it to URL and reload page
     * @param data
     */
    function submit(data){
        const newUrl = new URL('https://'+window.location.host+(window.location.pathname !== '' ? window.location.pathname : ''));
        const filterParameters = new URL(window.location.href);

        for (let v in data){
            if (
                data[v] !== false &&
                data[v] !== null &&
                data[v] !== '' &&
                data[v] !== undefined &&
                data[v].length !== 0){

                if (advanceVisible){
                    if (advancedSearchFieldName.includes(v)){
                        newUrl.searchParams.set(v, data[v]);
                    } else {
                        setValue(v, '');
                    }
                } else {
                    if (baseSearchFieldName.includes(v)){
                        newUrl.searchParams.set(v, data[v]);
                    } else {
                        setValue(v, '');
                    }
                }
            }
        }

        navigate('/products-list/s?'+newUrl.searchParams.toString());
        setAdvanceVisible(false);
    }

    function submitAllParameters(data){
        const newUrl = new URL('https://'+window.location.host+(window.location.pathname !== '' ? window.location.pathname : ''));
        const filterParameters = new URL(window.location.href);

        for (let v in data){
            if (
                data[v] !== false &&
                data[v] !== null &&
                data[v] !== '' &&
                data[v] !== undefined &&
                data[v].length !== 0){

                if (advancedSearchFieldName.includes(v) || baseSearchFieldName.includes(v)){
                    newUrl.searchParams.set(v, data[v]);
                } else {
                    setValue(v, '');
                }
            }
        }

        filterParameters.searchParams.forEach((value, key) => {
            if (!advancedSearchFieldName.includes(key) && !baseSearchFieldName.includes(key)) {
                newUrl.searchParams.set(key, value);
            }
        });

        navigate(window.location.pathname+'?'+newUrl.searchParams.toString());
        setAdvanceVisible(false);
    }

    function loadDataFromUrl(){
        const currentUrl = new URL(window.location.toString());
        const allValues = Object.fromEntries(currentUrl.searchParams.entries());

        reset(allValues);
    }

    return(
        <>
            <form name="search" onSubmit={handleSubmit(submit)} ref={formRef} >
                <div className="base" >
                    <input
                        disabled={advanceVisible}
                        type="search"
                        name="search"
                        placeholder={t('layout.header.search')}
                        {...register('search')} />
                    <button type="submit" className="fas fa-search" ></button>
                </div>
                {small === true ? '' :

                    <div className={'option-wrapper'} >
                        <div className={'description-search-box'}>
                            <input disabled={advanceVisible} type={'checkbox'} {...register('inDescription')} /> {t('layout.header.search-in-desc')}
                        </div>
                        <div className="advance" >
                            <button disabled={advanceVisible} type="button" onClick={toggleAdvanceVisible} >{t('layout.header.advance-search.button')}</button>
                            {advanceVisible ? <AdvancedSearch setVisible={setAdvanceVisible} register={register} getValues={getValues} setValue={setValue} /> : ''}
                        </div>
                    </div>
                }
            </form>
        </>
    );
}

/**
 * Cart button & number of item in it
 */
function Cart() {
    const {cart} = useContext(CartContext);
    const channelContext = useContext(ChannelContext);
    const {user} = useContext(UserContext);
    const {t} = useTranslation();

    if (cart === null){
        return <></>;
    }

    return(
        <>
            <div className="wrapper" >
                <span id={'cart'} >
                    <Link to="/cart" >
                        <img src={cartIcon} alt="cart"  />
                        <div className="number-items" >{cart.amount_item}</div>
                    </Link>
                </span>
            </div>
            {(!user && channelContext?.displayPriceNotLogged === true) || (user && channelContext?.displayPriceLogged === true) ? null :
                <Tooltip anchorSelect={'#cart'} className={'tooltip-info'} place={'bottom'} >
                    <b>{t('layout.header.summary-cart')}:</b> {cart.sum_netto} {t('products-list.before-tax')}, {cart.sum_brutto} {t('products-list.after-tax')}
                </Tooltip>
            }

        </>
    );
}

export {HeaderMiddle, Search as SearchHeader, Cart as CartHeader};
